/* .first_container {
  position: absolute;
  width: 100%;
  height: 100vh;
} */
.second_container {
  position: fixed;
  bottom: 0;
  background: #222;
  color: #fff;
  z-index: 1000000;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cooki_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  margin: 0 2rem;
}
.cooki_left p {
  font-size: 2rem;
}
.cooki_left a {
  color: #0080a4;
}
.cooki_left span {
  font-size: 2rem;
  margin: 0 0.75rem;
}
.cooki_right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15%;
}

.cooki_right button {
  font-size: 2rem;
  border: 1px solid #fff;
  padding: 1rem;
  border-radius: 2.5rem;
  height: 40px;
  background: transparent;
  font-size: 2rem;
  color: #fff;
  width: 115px;
}
.cooki_icon {
  font-size: 2rem;
}
@media (max-width: 1065px) {
  .second_container {
    height: 70px;
  }
  .cooki_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
    margin: 0 0.5rem;
  }
  .cooki_left p {
    font-size: 2.25rem;
  }
  .cooki_left span {
    font-size: 2.25rem;
  }

  .cooki_right {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 35%;
  }
  .cooki_right button {
    font-size: 2rem;
    border: 1px solid #fff;
    padding: 1rem;
    border-radius: 2.5rem;
    height: 40px;
    background: transparent;
    font-size: 2rem;
    color: #fff;
    width: 115px;
  }
}
/* @media (max-width: 768px) {
  .second_container {
    height: 70px;
  }
  .cooki_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 85%;
    margin: 0 2rem;
  }
  .cooki_left p {
    font-size: 2.25rem;
  }

  .cooki_right {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 15%;
  }
  .cooki_right button {
    font-size: 2rem;
    border: 1px solid #fff;
    padding: 1rem;
    border-radius: 2.5rem;
    height: 40px;
    background: transparent;
    font-size: 2rem;
    color: #fff;
    width: 115px;
  }
} */
@media (max-width: 767px) {
  .second_container {
    height: 70px;
    bottom: 7%;
  }
  .cooki_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    margin: 0 2rem;
  }
  .cooki_left p {
    font-size: 2rem;
  }
  .cooki_left span {
    font-size: 2rem;
  }
  .cooki_right {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 40%;
  }
  .cooki_right button {
    font-size: 1.25rem;
    border: 1px solid #fff;
    padding: 1rem;
    border-radius: 2.5rem;
    height: 40px;
    background: transparent;
    font-size: 2rem;
    color: #fff;
    width: 115px;
  }
}
@media (max-width: 599px) {
  .second_container {
    height: 60px;
    bottom: 5.5rem;
  }
  .cooki_left {
    width: 75%;
    margin: 0;
  }
  .cooki_left p {
    font-size: 1.25rem;
  }
  .cooki_left span {
    font-size: 1.25rem;
    margin: 0 0.5rem;
  }
  .cooki_right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25%;
  }
  .cooki_right button {
    font-size: 1rem;
    border: 1px solid #fff;
    padding: 0.25rem;
    border-radius: 1.5rem;
    height: 25px;
    background: transparent;
    color: #fff;
    width: 45px;
    margin: 0 0.25rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .second_container {
    height: 75px;
    bottom: 11rem;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 2) {
  .second_container {
    height: 75px;
    bottom: 9%;
  }
  .cooki_left {
    width: 70%;
    margin: 0 1rem;
  }
  .cooki_left p {
    font-size: 2.25rem;
  }
  .cooki_left span {
    font-size: 2.25rem;
  }

  .cooki_right {
    width: 30%;
  }
  .cooki_right button {
    border: 1px solid #fff;
    padding: 1rem;
    border-radius: 2.5rem;
    height: 50px;
    background: transparent;
    font-size: 2.5rem;
    color: #fff;
    width: 120px;
  }
}
