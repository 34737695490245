@import-normalize;
@font-face {
  font-family: drumshake_italic;
  src: url('./Fonts/drumshake_italic.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: drumshake_italic2;
  src: url('./Fonts/drumshake_italic2.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: drumshake_italic3;
  src: url('./Fonts/drumshake_italic-webfont.woff') format('opentype');
  font-display: swap;
}
@font-face {
  font-family:bubbleGum;
  src: url('./Fonts/Bubblegum.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: myraidproregular;
  src: url('./Fonts/myraidproregular.OTF') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: fatfrank;
  src: url('./Fonts/FatFrank\ Heavy\ Regular.ttf') format('opentype');
  font-display: swap;
}
html{
  font-size:55%;
  scroll-behavior: smooth;
  background-color:#2D2D2D;
}
@media(max-width:1065px){
  /* html{
    font-size: 50%;
  } */
}
body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebeb;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.OT_audio-blocked-indicator.OT_active{
  /* height: 100% !important; */
  display: none !important;
}
.OT_subscriber:hover .OT_edge-bar-item.OT_mode-auto{
  display: none;
}
.css-13cymwt-control{
  border:none !important;
  outline:none !important;
}
.css-qbdosj-Input input{
  text-align: center !important;
}
.OT_widget-container {
  position: relative !important;
  background-color: none !important;
  overflow: visible !important;
}
.OT_publisher .OT_video-element, .OT_subscriber .OT_video-element{
  position: relative !important;
}
::-webkit-scrollbar{
  /* -webkit-appearance: none; */
  /* background-color:transparent; */
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #1b8fe7; 
  border-radius: 10px;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
 width: 100%;
font-size: 3.25rem;
font-weight: 500;
text-align: center;
margin: 1.13rem 0 !important;


}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  height: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: "Poppins";
      width: 100%;
      font-size: 3rem;
      padding: 0rem 2.26rem;
      /* padding: 1.13rem 2.26rem; */
      text-align: left;
      border-radius: 1.13rem;
      margin: 1.13rem 0;
      border: 0.25rem solid #606060;
      text-align: center;

}