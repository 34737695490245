.Container {
    width: 100%;
    height: 400px;
    background-color: #2D2D2D;
    /* padding:50px 50px */

}

.Footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* font-family: fatfrank;
    font-weight: 100; */
}

.Logo {
    /* width: 45%; */
    /* padding: 30px; */
    height: 100%;
}

.Logo img {
    /* width: 100%; */
    height: 100%;
}

.Address,
.Menu {
    width: 30%;
    height: 100%;
}

.Menu {
    width: 35%;
}

.Title {
    font-size: 3.5rem;
    font-weight: 700;
    color: #fff;
    font-family: fatfrank;
}
.LinkTitle{
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    font-family: fatfrank;
}
.Menu ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;

}

.Menu ul li,.TermsAndPrivacyText li {
    font-size: 1.65rem;
    color: #fff;
    width: fit-content;
    transition: all 0.2s ease-in-out;
    border-bottom: 1.5px solid transparent;
    font-weight: 400;
    /* text-decoration: underline; */ 
}
.Menu ul li{
    font-size: 2.75rem;
}
.TermsAndPrivacyText li{
    text-decoration: underline;
}
.Menu ul li:hover {
    border-bottom: 1.5px solid #1b8fe7;
    /* transform: scale(1.1); */

}

.Address {
    height: 100%;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-between;
}

.FooterDetails {
    width: 50%;
    height: 100%;
    justify-content: flex-end;
    display: flex;
    padding: 50px;

    flex-direction: row;
}

.SocialMedia img {
    width: 15%;
    object-fit: contain;
    cursor: pointer;
}

@media(min-wdith:1065px) {
    .FooterDetails {
        justify-content: space-evenly;
    }

    .Menu,
    .Address {
        width: 100%;
    }
}

.Copyright p,
.TermsAndPrivacy p {
    font-size: 1.65rem;
    /* margin-top: 0.75rem; */
    color: #fff;
    font-weight: 600;
}

.SocialMedia {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SocialMedia svg {
    font-size: 5rem;
    color: #fff;
    cursor: pointer;
}

.Copyright,
.TermsAndPrivacy {
    display: flex;
    flex-direction: column;
}

/* .TermsAndPrivacy{
    margin-top: 3rem;
} */
@media (max-width:1064px) {

    /* .Container{
        padding: 25px;
    }
    .Menu,.Address{
        width: 50%;
    }
    .FooterDetails{
        width: 50%;
    } */
    /* .Logo{
        width: 50%;
    } */
    .FooterDetails {
        width: 100%;
        padding: 50px;
    }

    .Container {
        height: 100%;
        padding: 10rem 5rem;
    }

    .Footer {
        flex-direction: column-reverse;
    }

    .Logo,
    .Menu,
    .Address {
        width: 100%;
        padding: 0;
    }

    .Logo img {
        width: 100%;
    }

    .Title {
        font-size: 3.5rem;
    }

    .Menu ul li,.TermsAndPrivacyText li {
        font-size: 2.5rem;
        padding: 10px 0;
    }

    .SocialMedia {
        width: 100%;
        margin: 25px 0;
    }

    .SocialMedia svg {
        font-size: 3.5rem;
    }

    .Copyright {
        margin: 15px 0;
    }

    .bandaiLogo {
        display: none;
    }
}



.bandaiLogo {
    width: 40%;
    margin-top: 1.5rem;
}

.bandaiLogo img {
    width: 100%;
}
.wpclogo{
    width: 40%;
}
.wpclogo img{
    width: 100%;
}
.TermsAndPrivacy {
    flex-direction: row;
    justify-content: space-between;
}

.TermsAndPrivacyText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.TermsAndPrivacyText a {
    color: #fff;
    font-size: 1.25rem;
    text-decoration: underline;
}

.MFooter {
    display: none;
}
.FirstContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media(max-width:1065px) {
    .Container {
        /* padding-top: 10px; */
    }

    .Footer {
        display: none;
    }

    .MFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;

    }

    .MFooter .Address {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .SocialMedia {
        width: 70%;
    }

    .SocialMedia img {
        width: 15%;
    }

    .Copyright,
    .TermsAndPrivacyText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .TermsAndPrivacy {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .TermsAndPrivacyText a {
        text-decoration: underline;
        font-size: 2rem;
        margin: 3px 0;
    }
    .Mlogos{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 6rem;
        width: 60%;
        align-items: center;
    }
    .MbandaiLogo {
        width: 45%;
    }

    .MbandaiLogo img {
        width: 100%;
    }
    .Copyright p{
        font-size: 2rem;
    }
    .MWPCLogo{
        width: 35%;
        margin-left: 5rem;
        margin-bottom: 1rem;
    }
    .MWPCLogo img{
        width: 100%;
    }
}
@media(max-width:767px) {
    .FooterDetails {
        width: 100%;
        padding: 0;
    }

    .Container {
        height: 100%;
        padding: 6rem 0;
        
    }

    .Footer {
        flex-direction: column-reverse;
    }

    .Logo,
    .Menu,
    .Address {
        width: 100%;
        padding: 0;
    }

    .Logo img {
        width: 100%;
    }

    .Title {
        font-size: 2.5rem;
    }

    .Menu ul li,.TermsAndPrivacyText li {
        font-size: 1.75rem;
        padding: 5px 0;
    }

    .SocialMedia {
        width: 100%;
        margin: 25px 0;
    }

    .SocialMedia svg {
        font-size: 3rem;
    }

    .Copyright {
        margin: 15px 0;
    }

    .bandaiLogo {
        display: none;
    }
    .SocialMedia{
        width: 75%;
    }
}
@media(max-width:599px){
    .Copyright p{
        font-size: 1.7rem;
       /* white-space: nowrap; */
    } 
    .TermsAndPrivacyText a{
        font-size: 1.7rem;
    }
    .Container{
        padding:2rem 0;
    }
    .Menu ul li,.TermsAndPrivacyText li {
        padding: 5px 0;
    }
} 
@media(max-width:280px){
.Copyright p{
    font-size: 1.45rem;
}
}
.popup{
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #1b8fe7;
    border: 1rem solid #949393;
    border-radius: 20px;
    padding:1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 38rem;
}
.Overlay{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: transparent;
}
.popupImage{
    width: 40%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-60%);
}
.popupImage img{
    width: 100%;
    object-fit: contain;
}
.popupText{
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.popupText p{
    font-size: 2rem;
    text-align: center;
    color: #fff;
    width: 300px;
    font-weight: 500;
    /* word-wrap: break-word; */
    line-height: 25px;

}
.popupButton button{
    margin: 1.5rem;
    width: fit-content;
    padding: 1rem 3rem;
    background-color: transparent;
    border:3px solid #fff;
    outline:none;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 30px;
}
.ReportPopupButton{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    width: 100%;
}
.ReportPopupButton button{
    width: fit-content;
    margin: 1rem 0;
    padding: 1rem 3rem;
    background-color: transparent;
    border:1.75px solid #fff;
    outline:none;
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
    border-radius: 30px;
}
.popupInput textarea{
    width: 95%;
    padding:2rem 2rem;
    border-radius: 30px;
    color: #1b8fe7;

    outline:none;
    margin: 1rem;
}
.popupText form{
    display: flex;
    flex-direction: column;

}
.popupText form input,.popupText form textarea{
    border:none;
    outline:none;
    border-radius:  15px;
    margin: 5px 0px;
    padding:10px 20px;
    font-family: myraidproregular;
    color: #1b8fe7;
    font-size: 2rem;
}
.popupText form textarea{
    border-radius: 15px;
    font-family: myraidproregular;
    color: #1b8fe7;
    resize: none;
}
.popupText form input::-webkit-input-placeholder,.popupText form textarea::-webkit-input-placeholder{
    color: #1b8fe7;
    opacity: 1;
}
.popupText form input,.popupText form textarea{
    margin: 6px 0px;
    padding:10px 15px;
    font-size: 2rem;
}
@media(max-width:1064px){
    .popup{
        width: 40%;
    }
    .popupText form input,.popupText form textarea{
        margin: 6px 0px;
        padding:10px 20px;
        font-size: 2.5rem;
    }
}
@media(max-width:768px){
    .popup{
        width: 45%;
    }
    .popupText form input,.popupText form textarea{
        margin: 6px 0px;
        padding:10px 20px;
        font-size: 2rem;
    }
    .Mlogos{
        width:60%;
        margin: 5rem;
    }
    .MWPCLogo{
        width: 40%;
    }
    .MbandaiLogo{
        width: 50%;
    }
}
@media(max-width:712px){
    .popup{
        width: 50%;
    }
    .popupText form input,.popupText form textarea{
        margin: 6px 0px;
        padding:10px 20px;
        font-size: 2rem;
    }
    .MWPCLogo{
        width: 35%;
    }
}
@media(max-width:599px){
    .ContactSection{
        margin-top: 2rem;
    }
    .SupportContent{
        line-height: normal;
    }
    .ContactSection p{
        font-size: 11.5rem;
        margin-bottom: 0;
    }
    .ContactSection .StrokeContact{
        font-size: 4.5rem;
        font-weight: 500;
    }
    .popup{ 
    padding:1rem;
    width: 30rem;
    height: 50rem;
    }
    .popupText form input,.popupText form textarea{
        
        margin: 5px 0px;
        padding:10px 10px;
        font-size: 1.5rem;
    }
    .ReportPopupButton button{
       
        margin: 1.5rem;
        padding: 1rem 3rem;
        font-size: 1.5rem;   
    }
    .Contact{
        margin-top: 50%;
    }
    .Mlogos{
        width:60%;
        margin: 4rem;
    }
    .MWPCLogo{
        width: 40%;
        margin-left: 2rem;
        margin-bottom: 5px;
    }
    .MbandaiLogo{
        width: 50%;
    }
}
@media(max-width:280px){
    .ContactSection p{
        font-size: 9rem;
        margin-bottom: 1rem;
    }
    .ContactSection .StrokeContact{
        font-size: 3.5rem;
    }
    
}
.popupSection{
    position: relative;
    z-index: 999;
    
}
.wpclogo img, .MWPCLogo img {
    cursor: pointer;
}
.bandaiLogo img,.MbandaiLogo img{
    cursor: pointer;
}
.Menu ul li,.TermsAndPrivacyText li {
    /* font-size: 1.75rem; */
    list-style: none;
    /* padding: 15px 0; */
}
.warehousePopup {
    position: fixed;
    font-family: myraidproregular !important;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: -moz-fit-content;
    width: fit-content;
    transform: translate(-50%, -50%);
    border: 1rem solid #949393;
    border-radius: 20px;
    background-color: #0a84fe;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 15%; */
    width: 40rem;
    height: 22rem;
  }
  .warehousePopupText {
    margin-top: 10%;
  }
  .warehousePopupText p {
    font-size: 2rem;
    text-align: center;
    color: #fff;
    line-height: 25px;
    font-size: 2.25rem;
    font-weight: 100;
  }
  .popupOverlaySection {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 9999;
  }
  .ResendpopupImage {
    width: 40%;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    /* width: 50%; */
  }
  .ResendpopupImage img {
    width: 100%;
  }