.Container {
  width: 100%;
  height: 100vh;
  background: #cecece;
}
.success {
  width: 100%;
  height: 100vh;
  /* background-image: url("../../assests/Account_Successfully_Created.png"); */
}
.success img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.faild {
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.faild h1 {
  font-size: 4rem;
  font-weight: 500;
  margin-top: 5rem;

}
.verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* height: 100%; */
  background: #fff;
  width: 50%;
  height: 30rem;
}
.Image {
  width: 10rem;
  height: 10rem;
}
.Image img {
  width: 100%;
  height: 100%;
}
.Content {
  display: flex;
  align-items: center;
}
.Content p {
  font-size: 2.5rem;
  font-weight: 600;
}
@media (max-width: 1065px) {
 .success{
  width: 100%;
  height: 75vh;
 }
 .success img{
  object-fit: cover;
 }
}
@media (max-width: 767px) {
  .faild h1 {
    font-size: 2rem;
  } 
  .success{
    width: 100%;
    height: 50vh;
   }
}