.Container {
  width: 100%;
  /* height: 90vh; */
  height: 110vh;
  /* height: 100%; */
  padding: 5rem 11.5rem;
  padding-right: 0;
  background: url("../../assests/Login BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.Popup {
  position: absolute;
  top: 50%;
  left: 60.5%;
  transform: translate(-50%, -50%);
  /* width: 250px;
    height: 500px; */
  background-color: #3b82f6;
  padding: 25px 10px;

  border-radius: 15px;
  border: 7px solid #949393;
}
.PopupText {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 15%;
  font-weight: 300;
}
.image {
  width: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image img {
  width: 100%;
}
.Actions {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
}
.Contents {
  width: 80%;
  margin: auto;
}
.accept,
.Contents .Terms {
  width: 45%;
}
.Actions button {
  font-size: 17px;
  text-align: center;
  width: 100%;
  /* width: 100%; */
  color: #fff;
  background-color: transparent;
  border-radius: 30px;
  padding: 12px 20px;
  border: 1px solid #fff;
  font-weight: 100;
}
.Register {
  width: 56rem;
  margin-right: 11.5rem;
}
.Title {
  font-size: 4rem;
  margin-bottom: 1.7rem;
  text-align: center;
  color: #fff;
  font-style: italic;
  font-weight: 800;
}
.form {
  padding: 2.35rem;
  border-radius: 1.7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins";
  /* color: #333333d1; */
  color: #bdbdbd;
}
.date_input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins";
  /* color: #333333d1; */
  color: #bdbdbd;
}
.date_container::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins";
  color: #bdbdbd;
}
.input::-webkit-keygen-select {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.date_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  padding: 0rem 2rem;
  /* padding: 1.13rem 2.26rem; */
  text-align: left;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  height: 7.5rem;
  position: relative;
  -webkit-appearance: none; /* Disable default iOS styling */
  appearance: none;
}
.date_container select::-ms-expand {
  color: #000;
}
.date_container input {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 3rem;
  background-color: #fff;
  text-align: center;
  width: 100%;
}
.date_container select {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 3rem;
  background-color: #fff;
  text-align: center;
  /* color: #bdbdbd; */
  /* width: 13rem; */
  padding: 0 2rem;
  margin: 0 1rem;
}

.defaultOption {
  color: #dfdfdf;
  font-weight: 500;
  /* width: 13rem; */
}
.normalOption {
  color: #000;
}

.date_input {
  width: 125%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 3rem;
  background-color: #fff;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.password {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  padding: 0rem 2.26rem;
  /* padding: 1.13rem 2.26rem; */
  text-align: left;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  height: 7.5rem;
}
.password .input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 3rem;
  background-color: #fff;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.input {
  font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  height: 7.5rem;
  padding: 1.13rem 2.26rem;
  text-align: center;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  font-weight: 500;
  outline: none;
}
.Dateinput {
  /* font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  height: 7.5rem;
  padding: 1.13rem 2.26rem;
  text-align: center;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  font-weight: 500;
  /* color: #000; */
  /* text-align: center;  */
  /* color: #bdbdbd; */
}
.Dateinput2 {
  /* font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  height: 7.5rem;
  padding: 1.13rem 2.26rem;
  text-align: center;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  font-weight: 500; */

  color: #000;
}
.Dateinput::placeholder {
  color: red;
}

.Terms {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Terms input[type="checkbox"] {
  width: 1.7rem;
  height: 1.7rem;
}

.Terms p {
  width: 65%;
  margin-left: 2.26rem;
  font-size: 2rem;
  color: #3b82f6;
  font-weight: 600;
}
.Terms p a {
  font-size: 2rem;
  color: #3b82f6;
  font-weight: 600;
}
.formBtn,
.btnDisabled {
  /* font-family: "Poppins";
    width: 50%;
    font-size: 2.5rem;
    margin-top: 1.13rem;
    height: 5.68rem;
    padding: 1.13rem;
    text-align: center;
    border-radius: 1.13rem;
    margin: 1.13rem 0;
    color: #fff;
    border:none;
    background-color: #489ae6;
    cursor: pointer; */
  font-family: "Poppins";
  width: 50%;
  font-size: 3rem;
  margin-top: 1.13rem;
  height: 7rem;
  padding: 1.13rem;
  text-align: center;
  border-radius: 4rem;
  margin: 1.13rem 0;
  color: #fff;
  border: none;
  text-transform: uppercase;
  background-color: #3b82f6;
  cursor: pointer;
}
.btnDisabled {
  background-color: #606060;
  cursor: not-allowed;
}
.checkUser,
.CheckPassword {
  text-align: left;
  width: 100%;
}
.userAvail {
  font-size: 1.81rem;
  color: green;
  font-weight: 800;
}
.userInvaild,
.passInvalid {
  text-align: left;
  font-size: 1.81rem;
  color: maroon;
  font-weight: 800;
}
.userInvaild {
  text-align: center;
}
.DataInput {
  width: 100%;
  font-family: "Poppins";
  height: 7.5rem;
  padding: 1.13rem;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 1.13rem;
  margin-left: 0;
  border-radius: 1.13rem;
  margin-right: auto;
  border: 0.3rem solid gray;
  text-align: center;
  color: #3333;
}
.AgeRestrict {
  color: red;
  font-size: 1.81rem;
  margin: 2.84rem 0px;
  margin-right: auto;
  margin-top: 0;
  text-align: center;
}
label {
  text-align: left;
  font-size: 2rem;
  color: #333333d1;
  font-weight: 500;
  margin-right: auto;
}
.eyeIcon {
  width: 10%;
  cursor: pointer;
}
/* input::-webkit-input-placeholder {
  color: #3333338e;
} */
.CheckBox {
  padding: 2px;
  width: 40px;
  height: 40px;
  border: 4px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checked,
.NotChecked {
  width: 100%;
}
.NotChecked {
  opacity: 0;
}
.checked {
  opacity: 1;
}
.StateSelect {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  height: 100%;
  font-size: 3.5rem;
}
@keyframes slideDown {
  0% {
    height: 112.5px;
  }
  /* 25%{
        height: 225px;
    }
    50%{
        height: 337.5px;
    } */
  100% {
    height: 450px;
  }
}
@keyframes slideUp {
  0% {
    height: 450px;
  }
  /* 25%{
        height: 225px;
    }
    50%{
        height: 337.5px;
    } */
  100% {
    height: 0px;
  }
}
.selectInput {
  position: relative;
}
.AllState {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  height: 450px;
  overflow-y: scroll;
  background-color: #333;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 15px;
  /* animation: slideDown 500ms ease; */
  /* transition: all 0.5s ease; */

  /* position: absolute;
    top: 0;
    left: 0; */
}
.stateUp {
  animation: slideUp 500ms ease;
}
.StateSelectHide {
  color: #3333338e;
}
.AllState::-webkit-scrollbar {
  display: none;
}
.AllState input {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  border-bottom: 0.5px solid #fff;
  padding: 10px;
  outline: none;
  color: #fff;
}
.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input input {
  width: 100%;
}

.info {
  width: 10%;
  display: flex;
  align-items: center;
  margin: auto;
}
.info img {
  width: 100%;
  cursor: pointer;
}

/* .passwordInput{
    width: 100%;
    height: 100%;
    border:none;
    outline:none;
    font-family: "Poppins";
    width: 100%;
    font-size: 3rem;
    background-color: #fff;
    padding: 1.13rem 2.26rem;
    text-align: left;
    border-radius: 1.13rem;
    margin: 1.13rem 0;
} */
.popup {
  width: 45rem;
  height: 43rem;
  padding: 50px;
  border-radius: 15px;
  border: 7.5px solid #949393;
  position: relative;
  background-color: #0a84fe;
  position: absolute;
  top: 50%;
  z-index: 999;
  left: 50%;
  transform: translate(-5%, -50%);
}
.content {
  margin: 3rem 0;
}
.content ul {
  display: flex;
  flex-direction: column;
  list-style: circle;
  font-size: 2rem;
}
.content ul p {
  color: #fff;
  padding: 8px;
  text-align: center;
}
.content ul li {
  margin: 7px 0px;
  color: #fff;
}
.action {
  /* margin: auto; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.action button {
  margin: auto;
  width: 75%;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  outline: none;
  padding: 10px;
  font-size: 2rem;
}
.image {
  width: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image img {
  width: 100%;
}
.StateSelectCenter {
  text-align: center;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  color: #3333333d;
  height: 100%;
  font-size: 3rem;
}
.StateSelectCenter::-webkit-input-placeholder {
  color: #3333338e !important;
}
.DataInput {
  color: #000;
  text-align: center;
  position: relative;
}
.hideDate {
  width: 100%;
  font-family: "Poppins";
  height: 7.5rem;
  padding: 1.13rem 2.26rem;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 1.13rem;
  margin-left: 0;
  border-radius: 1.13rem;
  margin-right: auto;
  border: 0.25rem solid #606060;
  text-align: center;
  color: #bdbdbd;
  position: relative;
}
/* .DataInput::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0;
} */
/* .hideDate::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0;
} */
.DataInput::-webkit-input-placeholder {
  opacity: 1;
  color: #bdbdbd;
}
.DataInput {
  opacity: 1;
  color: #bdbdbd;
}

@media (min-width: 767px) and (max-width: 1056px) {
  .Container {
    width: 100%;
    padding: 1.7rem;
    padding-right: 0;
    justify-content: center;
  }
  .Register {
    width: 60%;
    margin-right: 1.7rem;
  }
  .popup {
    width: 50%;
  }
  .input {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 5.11rem;
  }
  .password .input {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  /* .Terms p {
    width: 70%;
    margin-left: 2.26rem;
    font-size: 2rem;
    color: #3b82f6;
    font-weight: 600;
  } */
}
@media (max-width: 767px) {
  .Container {
    padding: 1.7rem;
    padding-right: 0;
    justify-content: center;
  }
  .Register {
    margin-right: 1.7rem;
    width: 56rem;
    flex-direction: column;
  }
  /* .input::-webkit-input-placeholder {
    font-size: 1.7rem;
    color: gray;
  } */
  .Title {
    font-size: 2.5rem;
  }

  .Terms input[type="checkbox"] {
    width: 2.26rem;
    height: 2.26rem;
  }
  .Terms p {
    font-size: 1.36rem;
    width: 45%;
  }
  .Terms p a {
    font-size: 1.36rem;
  }
  .btnDisabled,
  .formBtn {
    font-size: 2rem;
    font-weight: 500;
    height: 5.11rem;
  }
  .DataInput {
    font-size: 1.7rem;
    height: 5.11rem;
  }
  .hideDate {
    height: 5rem;
    font-size: 1.7rem;
    font-weight: 600;
  }
  .popup {
    width: 32rem;
    left: 50%;
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
    transform: translate(-50%, -50%);
  }
  .image {
    width: 35%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content {
    margin: 0% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    /* padding: 9rem 0px; */
    margin-top: 15%;
  }
  .content ul{
    font-size: 1.75rem;
  }
  .action button {
    margin: 1rem;
    width: 85%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 8px 0;
    font-size: 1.75rem;
  }

  .input {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 5.11rem;
  }
  .password .input {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
}
@media (min-width: 390px) and (max-width: 420px) {
  .Terms p {
    font-size: 1.36rem;
    width: 65.5%;
  }
}
@media (max-width: 380px) {
  .Terms p {
    font-size: 1.36rem;
    width: 73%;
  }
}
@media (max-height: 630px) {
  .Container {
    height: 100%;
    padding: 5.68rem;
    padding-right: 0;
  }
  .Register {
    margin-right: 5.68rem;
  }
  .input {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 5.11rem;
  }
  .password .input {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
}

.DataInput {
  color: #000;
  text-align: center;
  position: relative;
}

/* .DataInput::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0;
}
.hideDate::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0;
} */
@media (max-width: 1065px) {
  .Container {
    background: #fae863;
    padding: 6rem 0;
    height: auto;
  }
  .Register {
    margin-right: 25px;
    margin-left: 25px;
  }
  .form {
    -webkit-box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 1);
  }

  .DataInput {
    height: 8rem;
    font-size: 2.7rem;
    font-weight: normal;
  }

  .DataInput {
    /* height: 6.11rem; */
    font-size: 2.7rem;
  }
  .info {
    width: 10%;
  }
  /* .DataInput{

    } */
  /* .hideDate::-webkit-input-placeholder,
  .DataInput::-webkit-input-placeholder {
    opacity: 1;
  } */
  .forgotInput input::-webkit-input-placeholder {
    color: #33333d7c;
    font-size: 2.75rem;
    text-align: center;
    text-transform: uppercase;
  }
  .passwordInput::-webkit-input-placeholder {
    font-size: 2.7rem;
  }
  .EmailInput label,
  .PasswordInput label {
    font-size: 1.5rem;
  }
  .Terms p a {
    /* font-size: 1.36rem; */
    text-decoration: underline;
  }
  .CheckBox {
    width: 25px;
    height: 25px;
    border: 3px solid gray;
  }
  .AgeRestrict {
    margin-bottom: 0;
  }
  .Popup {
    z-index: 99;
    width: 80%;
  }
  .AgeRestrict {
    font-size: 1.5rem;
  }
  label {
    font-size: 2.5rem;
    font-weight: 600;
  }

  .input {
    height: 7.11rem;
    font-size: 3.25rem;
    font-weight: 500;
    text-align: center;
  }
  .Dateinput {
    height: 7.11rem;
    font-size: 3.25rem;
    font-weight: 500;
    text-align: center;
  }
  .Dateinput2 {
    height: 7.11rem;
    font-size: 3.25rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 7.11rem;
    font-size: 3.25rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 7.11rem;
  }
  .password .input {
    font-size: 3.25rem;
    font-weight: 500;
    text-align: center;
  }
}
@media (max-width: 765px) {
  .Popup {
    z-index: 99;
    width: 80%;
    left: 50%;
  }
  .input {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 5.11rem;
  }
  .password .input {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .Dateinput {
    font-size: 2rem;
    font-weight: 500;
    height: 6rem;
    text-align: center;
    outline: none;
    background: transparent;
    padding: 0 9rem;
  }
  .Dateinput2 {
    height: 6rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
}
@media (max-width: 712px) {
  .info {
    width: 10%;
  }
  .eyeIcon {
    width: 12%;
  }

 
  .input::-webkit-input-placeholder {
    font-weight: normal;
  }
  .input {
    height: 6.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 6.11rem;
  }
  .password .input {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .DataInput {
    height: 6.11rem;
  }
}

@media (max-width: 599px) {
  .popup {
    width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 35rem;
    justify-content: center;
    padding: 3rem;
    transform: translate(-50%, -50%);
  }
  .image {
    width: 35%;
  }
  .content {
    margin: 0% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    /* padding: 9rem 0px; */
    margin-top: 15%;
  }

  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 1.75rem;
  }
  .action button {
    margin: 1rem;
    width: 85%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 8px;
    font-size: 1.75rem;
  }

  .DataInput {
    height: 6.11rem;
    font-size: 2.5rem;
    font-weight: normal;
    font-weight: 500;
    display: block;
    display: flex;
  }
  .input {
    height: 6.11rem;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 6.11rem;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
    font-weight: 500;
  }
  .password {
    height: 6.11rem;
  }
  .password .input {
    font-size: 2.5rem;
    font-weight: 500;
  }
  label {
    font-size: 1.75rem;
  }
  .date_container {
    height: 6.11rem;
    padding: 0;
    justify-content: space-around;
  }
  .date_container select {
    font-size: 2.5rem;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #day {
    width: 20%;
  }
  #month {
    width: 30%;
  }
  #year {
    width: 30%;
  }
  #closeicon {
    width: 20%;
  }
}
@media (max-width: 350px) {
  .Register {
    width: 100%;
  }
  .input {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .hideDate {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #bdbdbd;
  }
  .password {
    height: 5.11rem;
  }
  .password .input {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
}
@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .popup {
    width: 45rem;
    height: 40rem;
    padding: 50px;
    border-radius: 15px;
    border: 7.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content {
    margin: 10% 0;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2rem;
  }
  .content ul p {
    color: #fff;
    padding: 8px;
    text-align: center;
  }
  .content ul li {
    margin: 7px 0px;
    color: #fff;
  }
  .action {
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .action button {
    margin: auto;
    width: 75%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 10px;
    font-size: 2rem;
  }
  .image {
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .popup {
    width: 45rem;
    left: 50%;
    height: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4rem;
    transform: translate(-50%, -50%);
  }
  .image {
    width: 40%;
  }
  .content {
    margin: 0% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    /* padding: 9rem 0px; */
    margin-top: 15%;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2.5rem;
  }

  .action button {
    margin: 1rem;
    width: 85%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 8px;
    font-size: 2.5rem;
  }

  .Terms p {
    width: 69%;
  }
}
@media screen and (max-device-width: 1400px) and (orientation: landscape) {
  .popup {
    width: 45rem;
    height: 46rem;
    padding: 40px;
    border-radius: 15px;
    border: 7.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    z-index: 999;
    left: 43%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-top: 10%;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2.5rem;
  }
  .content ul p {
    color: #fff;
    padding: 8px;
    text-align: center;
  }
  .content ul li {
    margin: 7px 0px;
    color: #fff;
  }
  .action {
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .action button {
    margin: 1.25rem;
    width: 75%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .image {
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-device-width: 1200px) and (orientation: landscape) {
  .popup {
    width: 45rem;
    height: 46rem;
    padding: 40px;
    border-radius: 15px;
    border: 7.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    z-index: 999;
    left: 34%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: 10%;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2.5rem;
  }
  .content ul p {
    color: #fff;
    padding: 8px;
    text-align: center;
  }
  .content ul li {
    margin: 7px 0px;
    color: #fff;
  }
  .action {
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .action button {
    margin: 1.25rem;
    width: 75%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .image {
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .popup {
    width: 45rem;
    height: 46rem;
    padding: 40px;
    border-radius: 15px;
    border: 7.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-top: 10%;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2.5rem;
  }
  .content ul p {
    color: #fff;
    padding: 8px;
    text-align: center;
  }
  .content ul li {
    margin: 7px 0px;
    color: #fff;
  }
  .action {
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .action button {
    margin: 1.25rem;
    width: 75%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px;
    font-size: 2.5rem;
  }
  .image {
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.ShowCalender {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ShowCalenderOverlay {
  background-color: #00000025;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 999999999;
}
.CalenderContent {
  background-color: #fff;
  border-radius: 25px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  width: 500px;
  height: 500px;
}
.CalenderContent > button {
  font-size: 1.75rem;
}
@media (min-width: 1024px) and (max-device-width: 1050px) {
  .Terms p {
    width: 53%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 915px) and (-webkit-min-device-pixel-ratio: 1) {
  .Terms p {
    width: 57%;
  }
}
.warehousePopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999;
  top: 50%;
  left: 50%;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 20px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
  height: 22rem;
}
.warehousePopupText {
  margin-top: 10%;
}
.warehousePopupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.25rem;
  font-weight: 100;
}
.popupOverlaySection {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 9999;
}
.ResendpopupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  /* width: 50%; */
}
.ResendpopupImage img {
  width: 100%;
}