.containerScreen{
    width: 100%;
    height: 100vh;
    background-color: #000;
    position: relative;
}
.popup{
    width: 100%;
    background-color: #000000;
    /* height: 50%; */
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* position: absolute; */
   
}

.Section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Section h1{
    font-size: 2rem;
    color: #fff;
}
