.Banner{
    width: 100%;
    height: auto;
    position: relative;
}
.Background{
    width: 100%;
    height: auto;
}
.Background img{
    width: 100%;
    height: auto;
}
.WinGame{
    position: absolute;
    bottom: 10%;
    right: 5%;

    overflow: hidden;
    width: 25%;
}
.WinGame img{
    width: 100%;
    border:4px solid #fff;
    border-radius: 25px;
}.FreeWinGame{
    width: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-10%,75%);
}
.FreeWinGame img{
    width: 100%;
}
@media(max-width:1065px){
    .Banner{
        height: auto;
    }
    .WinGame img{
        object-fit: cover;
    }
    .Background img{
        height: 100%;
        object-fit: cover;
    }
    .Background {
        height: 100%;
    }
}