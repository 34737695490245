.Container {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(27, 143, 231, 1) 0%,
    rgba(19, 192, 236, 1) 50%,
    rgba(32, 122, 224, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* cursor: pointer; */

}

.Section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-items: center; */
  min-height: 100vh;
  max-width: 1600px;
}

.hideVideo {
  display: none;
}

.video {
  display: flex;
  width: 100%;
  position: relative;
  height: 50vh;
  border-radius: 50px;
  overflow: hidden;
}

.Controls {
  margin-top: 3%;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 200px;
}

.GameScreen {
  width: 43%;
  /* min-height: 100vh; */
  padding-top: 0rem;
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.AllGameSectin {
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}
.GameScreen .Screen {
  width: 95%;
  /* min-height: 30vh; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  top: 0;
  transform: translateY(2%);
  /* height: 90%; */
}

.GameScreen .Screen .video {
  width: 100%;
  height: auto;
}

.Screen {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 45vh;
  /* height: 90%; */
}

.Icons {
  position: absolute;
  z-index: 999;
  top: 5%;
  left: 5%;
}

.spectator,
.queStatus {
  margin: 1rem;
  position: relative;
}

.spectator img,
.queStatus img {
  width: 50%;
}

.spectator {
  margin-top: 2rem;
}

.spectator span,
.queStatus span {
  background-color: #e50000;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-40%, -100%);
  font-size: 1.35rem;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Camera,
.PrizeReset,
.LastWin {
  width: 65%;
  margin: 0 auto;
}
.PrizeReset {
  width: 55%;
  margin: 25px auto;
}
.Camera button,
.PrizeReset button,
.LastWin button {
  width: 100%;
}

.Camera button img,
.PrizeReset button img,
.LastWin button img {
  width: 100%;
}

.PlayImage button,
.PlayImage > div:nth-child(3) {
  width: 100%;
  /* width: 20rem; */
}

.PlayImage button img {
  width: 100%;
  height: 100%;
}

.video,
.video > div {
  width: 100%;
  height: auto;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
}

.Controls > div {
  width: 35%;
}
.Controls .Center {
  width: 37%;
}

.Controls .Guide {
  width: 100%;
  margin: 0 auto;
  height: auto;
}

.Controls .Guide img {
  width: 100%;
  object-fit: contain;
}

.LastWin {
  margin: 0 auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Report {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Report button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.Report button img {
  width: 75%;
}

.ExtraGames {
  width: 25%;
  margin: 5% 0;
}

.LeftSide,
.MLeftSide {
  width: 25%;
}

.ExtraButton button {
  font-family: drumshake_italic;
  font-size: 6rem;
  background-color: #fd8204;
  color: #fff;
  padding: 10px 0;
  width: 90%;
  border-radius: 15px;
  border: 2px solid #fff;
  outline: none;
}

.Description p {
  text-align: center;
  font-family: drumshake_italic;
  font-size: 5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.AllGames {
  /* display: flex; */
  /* flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* max-height: 64vh; */

  /* overflow: scroll; */
}

.AllGames::-webkit-scrollbar {
  display: none;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.AllGames .Game {
  width: 97%;
  /* pointer-events:visible; */
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0),
    0.2px 0.2px 15px #00000019, inset -0.2px -0.2px 15px rgba(0, 0, 0, 0.25),
    inset 0.2px 0.2px 15px rgba(00, 0, 00, 0.25);
  margin: 1.13rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 2.26rem;
  /* aspect-ratio: 4/3; */
  cursor: pointer;
}

.TicketPrice {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Ticket {
  width: 20%;
}

.Ticket img {
  width: 100%;
}

.GameName p {
  font-size: 1.375rem;
  text-align: center;
  margin: 1rem 0;
  margin-bottom: 0;
  font-weight: 600;
}

/* .Price p {
  font-family: drumshake_italic;
  font-size: 4rem;
  margin: 0 1rem;
} */
.free{
  font-family: "Poppins";
  font-weight: 900;
  font-size: 2.5rem;
  font-style: italic;
  margin: 5px 1rem;
}
.Price {
  font-family: "Poppins";
  font-weight: 900;
  font-size: 2.5rem;
  /* font-style: italic; */
  margin: 5px 1rem;
}
.Price {
  font-family: bubblegum;
  /* font-family: drumshake_italic; */
  font-weight: normal;
}

.info {
  width: 15%;
}

.info img {
  width: 100%;
}

.Image img {
  width: 100%;
  border-top-right-radius: 2.26rem;
  border-top-left-radius: 2.26rem;
}

video {
  width: 100%;
  height: 100%;
}

.video img,
.video iframe,
.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup,
.reportPopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999999;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 30px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
  height: 25rem;
}
.reportPopup {
  height: auto;
}
.popupImage {
  width: 40%;
  z-index: 99;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.popupImage img {
  width: 100%;
  object-fit: contain;
}

.popupText {
  margin-top: 15%;
  position: relative;
  z-index: 99;
  padding: 0rem 2rem;
}

.popupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.5rem;
  font-weight: 100;
}
.popupButton,
.popupButtonSubmit {
  width: 76%;
  position: relative;
  z-index: 999;
  padding: 0rem 2rem;
}
.popupButton {
  display: flex;
  flex-direction: row;
}
.popupButton a {
  width: 100%;
}
.popupButton a button {
  width: 100%;
}
.popupButtonSubmit a {
  width: 45%;
}
.popupButton button,
.popupButtonSubmit button {
  margin: 1.5rem 0.5rem;
  padding: 1.5rem 0rem;
  width: 100%;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 600;
  border-radius: 30px;
  font-family: myraidproregular;
}
.warehousePopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-25%, -46%);
  border: 1rem solid #949393;
  border-radius: 20px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 300px;
  height: 20rem;
}
.warehousePopupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.25rem;
  font-weight: 100;
}
.ResendpopupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  /* width: 50%; */
}
.ResendpopupImage img {
  width: 100%;
}
.popupOverlaySection {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 9999;
}
.popupButtonSubmit button {
  width: 100%;
}
.popupButtonSubmit button {
  border-width: 2px;
}

.ReportPopupButton,
.ReportPopupButtonCategory {
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0rem 2rem;
  flex-direction: column;
  position: relative;
  z-index: 99;
}
.ReportPopupButtonCategory {
  margin-top: 12%;
}
.ReportPopupButton button,
.ReportPopupButtonCategory button {
  margin: 1rem;
  padding: 1rem 0rem;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 100;
  border-radius: 30px;
  font-family: myraidproregular;
}
.popupInput {
  width: 100%;
  position: relative;
  z-index: 99;
}
.popupInput textarea {
  /* min-width: 270px; */
  /* max-width: 270px; */
  /* min-height: 225px; */
  /* max-height: 225px; */
  padding: 2rem 2rem;
  font-family: myraidproregular;
  border-radius: 20px;
  font-size: 2.5rem;
  color: #1b8fe7;
  /* overflow-y: scroll; */
  outline: none;
  margin: 1rem 0;
  resize: none;
  width: 100%;
  font-weight: 800;
}
/* .popupInput textarea::-webkit-textfield-decoration-container{
    display: none;
} */
.popupSubmit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
}
.popupSubmit button {
  margin: 0.5rem;
  padding: 1rem 3rem;
  background-color: transparent;
  font-family: myraidproregular;

  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 500;
  border-radius: 30px;
}

.LeftSide,
.MLeftSide {
  /* position: sticky; */
  top: 0;
  height: 100vh;
}
.GameContent {
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.NowPlaying {
  width: -moz-fit-content;
  width: fit-content;
  /* width: 90%; */
  width: 30rem;
  position: absolute;
  top: 0;
  right: 2.5%;
  display: flex;
  background-color: #e50000;
  padding: 2rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
}

.NowPlayingTitle p {
  font-family: drumshake_italic;
  font-size: 3.75rem;
  color: #fff;
}

.CurrentImage {
  width: 100%;
  height: 75%;
  object-fit: cover;
}

.CurrentImage {
  /* border-radius: 20px; */
  /* border: 5px solid #fff; */
  overflow: hidden;
  margin-bottom: 1rem;
}

.CurrentImage img {
  width: 100%;
  border: 5px solid #fff;
  border-radius: 20px;
}

.CurrentTitle {
  position: relative;
  z-index: 99;
}

.CurrentTitle p {
  font-size: 1.75rem;
  color: #fff;
  /* margin-top: 1rem; */
  font-weight: 500;
  text-align: center;
}

.CloseIcon {
  z-index: 9;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  border-radius: 50%;
  background-color: #e50000;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.CloseIcon svg {
  color: #fff;
  font-size: 3rem;
}

.LastWinPopup {
  position: absolute;
  top: 50%;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.VideoSection {
  width: 400px;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
  border: 10px solid #949393;
  /* border: 10px solid #1b8fe7; */
  /* outline: 10px solid #949393; */
  border-radius: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.PlayIcon button img {
  width: 20%;
}

.PlayImage {
  margin: 0 auto;
  width: 192px;
  height: 192px;
  position: relative;
  /* min-width:130px */
}

.PlayImage button,
.PlayImage > div:nth-child(3) {
  position: relative;
  z-index: 99;
  min-width: 12rem;
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
/* .PlayImage button, .PlayImage > div:nth-child(3){
  position: relative;
  z-index: 99;
  min-width: 12rem;
  height: 100%;
  width: 100%;
} */

.GameScore {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.GameScore img {
  width: 100%;
  z-index: 9;
}

.GameScore span {
  position: absolute;
  top: 50%;
  font-size: 4rem;
  font-family: bubbleGum;
  color: #000;
  -webkit-text-stroke: 1px #fff;
  left: 50%;
  transform: translate(-50%, -50%);
}
.VideoSection div {
  background-color: #1b8fe7;
  width: 100%;
  height: 100% !important;
}

.VideoSection video {
  background-color: #000;
  border: 10px solid #1b8fe7;
  border-radius: 30px;
  /* width: 100%; */
  /* height: 100%; */
  /* border-radius: 30px; */
  /* border: 14px solid #1b8fe7; */
  /* outline: 14x solid #1b8fe7; */
}
.hideVideopopup {
  display: none;
}
.VideoSection .VideoEmpty {
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoEmpty p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
}

.secondChancePopupOverlay{
  position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    background-color: #0000005d;
    z-index: 99;
}
.secondChancePopup{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  width: 25vw;
  align-items: center;
}
.secondChancePopupClose{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.secondChancePopupClose img{
  width: 10%;
  height: 100%;
  cursor: pointer;
}
.secondChancePopupTop{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 20vw;
}
.secondChancePopupTop img{
  width: 100%;
  height: 100%;
}
.secondChancePopupContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../assests/Box.png");
  background-size: contain;  
  background-position: center; 
  background-repeat: no-repeat;  
  height: 31vh;
  margin: 3rem 0;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
}
.secondChancePopupContentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 1rem;
}
.secondChancePopupContentRowLeft{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 33%;
}
.secondChancePopupContentRowLeft p{
  font-size: 8rem;
  font-family: drumshake_italic;
  color: rgb(3, 3, 3);
  -webkit-text-stroke: 2px white;
}
.secondChancePopupContentRowRight{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 50%;
}
.secondChancePopupTicketIcon{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 4vw;
  height: 4vh;
}
.secondChancePopupTicketIcon img{
  width: 100%;
  height: 100%;
}
.secondChancePopupTicketAmount{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.secondChancePopupTicketAmount p{
  font-size: 8rem;
  font-family: drumshake_italic;
  color: rgb(3, 3, 3);
  -webkit-text-stroke: 2px white;
}
.strikeLineImage{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 99999;
  width: 10rem;
  height: 2rem;
}
.strikeLineImage img{
width: 100%;
height: 100%;
}
.secondChancePopupButtonContainer{
  width: 35rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);

}


@media (max-width: 1065px) {
  .Container {
    height: 100%;
  }
  .AllGameSectin {
    min-height: 0;
  }
  .GameScreen {
    height: auto;
  }
  .Section {
    width: 90%;
    margin: 0 auto;
    height: auto;
    margin-top: 5%;
    align-items: center;
  }

  .Section {
    flex-direction: column-reverse;
    width: 100%;
  }

  .ExtraGames,
  .GameScreen,
  .LeftSide,
  .MLeftSide {
    width: 100%;
  }

  .LeftSide,
  .MLeftSide {
    height: auto;
    width: 100%;
  }

  .NowPlaying {
    top: 0;
    width: fit-content;
    /* bottom: 0; */
    /* left: 0; */
    right: 0;
    /* position: fixed; */
  }

  .NowPlayingTitle {
    /* margin-top: 7.5%; */
    position: relative;
    z-index: 99;
  }

  .NowPlayingTitle p {
    font-size: 3.5rem;
  }

  .CurrentTitle p {
    padding: 1rem;
    font-size: 3.5rem;
  }

  .CurrentImage {
    /* border-radius: 20px; */
  }

  .CurrentImage {
    width: 90%;
  }

  /* .CloseIcon{
        top: 0;
        transform: translate(-50%,-50%);
        bottom: auto;
        left: 50%;
        z-index: 9;
    } */
  .NowPlaying {
    z-index: 99;
    width: 50rem;
    padding: 1rem 1rem;
    /* border-top-right-radius: 20px;
        border-top-left-radius: 20px; */
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .CloseIcon svg {
    transform: rotate(180deg);
  }

  .CurrentTitle p {
    margin-top: 0;
  }

  .queStatus img,
  .spectator img {
    width: 75%;
  }

  .queStatus span,
  .spectator span {
    font-size: 2rem;
    /* padding: 2px 6px; */
    padding-top: 1px;
    text-align: center;
    width: 3rem;
    height: 3rem;
  }

  /* video{
        border-radius: 25px;
    } */
  .video {
    border-radius: 30px;
  }

  .GameScore span {
    font-size: 5rem;
  }
  .ExtraButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .ExtraButton button {
    font-family: drumshake_italic;
    font-size: 5rem;
    background-color: #fd8204;
    margin: auto;
    color: #fff;
    padding: 10px 0;
    width: 60%;
    border-radius: 15px;
    border: 2px solid #fff;
    outline: none;
    margin-top: 5rem;
  }
  .Controls {
    width: 90%;
    margin-top: 4%;
  }
  .Controls .Left,
  .Controls .Right {
    width: 85%;
  }
  .Controls .Center {
    width: 100%;
  }
  .PlayImage button img,
  .PlayImage > div:nth-child(3) svg {
    width: 100%;
  }
  .Report button {
    width: 35%;
  }
  .Description p {
    margin-top: 1.5rem;
  }
  .queStatus,
  .spectator {
    font-size: 1.5rem;
    padding: 0.5px 2px;
  }
  .queStatus img,
  .spectator img {
    /* width: 45%; */
  }
  .GameScreen {
    margin-top: 2.5rem;
  }
  .Controls .GameScore {
    width: 40%;
  }
  .AllGames {
    justify-content: center;
    width: 100%;
  }
  .GameContent{
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ExtraGames {
    width: 80%;
  }
  .CloseIcon {
    padding: 1.25rem;
  }

  .CloseIcon svg {
    font-size: 3.5rem;
  }
  .Icons {
    left: 14%;
  }
  .PlayImage {
    margin: 0 auto;
    width: 192px;
    height: 192px;
    position: relative;
    /* min-width:130px */
  }
  .LowPowerPopup{
    position: fixed;
    font-family: myraidproregular !important;
    z-index: 99999999;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
    border: 1rem solid #949393;
    border-radius: 30px;
    background-color: #0a84fe;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 15%; */
    width: 40rem;
    height: 25rem;
  }
  .LowPowerPopupImage {
    width: 35%;
    z-index: 99;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }
  
  .LowPowerPopupImage img {
    width: 100%;
    object-fit: contain;
  }
  .LowPowerPopupText {
    margin-top: 15%;
    position: relative;
    z-index: 99;
    padding: 0rem 2rem;
  }
  
  .LowPowerPopupText p {
    text-align: center;
    color: #fff;
    line-height: 25px;
    font-size: 2.25rem;
    font-weight: 100;
  }
  
}
@media (max-width: 1065px) {
  .LastWinPopup {
    position: fixed;
    width: 90%;
  }
  .VideoSection {
    width: 500px;
    height: 400px;
    border-width: 5px;
    outline-width: 5px;
    border-radius: 35px;
  }
  .VideoSection video {
    border-width: 5px;
    border-radius: 30px;
  }
  .secondChancePopup{
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 35vw;
  }
  .secondChancePopupTop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
  }
  .secondChancePopupContent {  
    margin:  0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .secondChancePopupContentRowLeft{
    width: 33%;
  }
  .secondChancePopupContentRowLeft p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupContentRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 50%;
  }
  .secondChancePopupTicketIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    height: 4vh;
  }
  .secondChancePopupTicketAmount p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupButtonContainer{
    width: 45vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 27%);
  
  }
  
}
@media (max-width: 820px) {
  .Icons {
    left: 5%;
  }
  .GameScreen {
    margin-top: 4.25rem;
  }
}

@media (max-width: 768px) {
  .Overlay {
    border-radius: 30px;
  }
  .LoaderDiv {
    width: 50px;
    height: 50px;
    border-bottom: 2px solid #fff;
  }
  .Loader h1 {
    font-size: 1rem;
  }
  .Icons {
    left: 5%;
  }
  .GameScreen {
    margin-top: 4rem;
  }
  .secondChancePopup{
    top: 16%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
  }
  .secondChancePopupTop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
  }
  .secondChancePopupContent {  
    margin:  0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .secondChancePopupContentRowLeft{
    width: 33%;
  }
  .secondChancePopupContentRowLeft p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupContentRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 50%;
  }
  .secondChancePopupTicketIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 9vw;
    height: 4vh;
  }
  .secondChancePopupTicketAmount p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupButtonContainer{
    width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 35%);
  
  }
}

@media (max-width: 767px) {
  .CloseIcon {
    padding: 1rem;
  }

  .CloseIcon svg {
    font-size: 2.5rem;
  }

  .Container {
    height: 100%;
  }

  .Section {
    width: 100%;
    margin: 0 auto;
  }

  .Section {
    flex-direction: column-reverse;
  }

  .ExtraGames,
  .GameScreen,
  .LeftSide,
  .MLeftSide {
    width: 100%;
  }

  .LeftSide,
  .MLeftSide {
    height: auto;
    width: 100%;
  }

  .GameScreen {
    margin-top: 6rem;
  }

  .NowPlaying {
    top: 0;
    width: fit-content;
    /* bottom: 0;
        left: 0; */
    right: 0;
    position: absolute;
  }

  .NowPlayingTitle {
    /* margin-top: 7.5%; */
    position: relative;
    z-index: 99;
  }

  .NowPlayingTitle p {
    font-size: 3rem;
  }

  .CurrentTitle p {
    font-size: 2.25rem;
  }

  .CurrentImage {
    /* border-radius: 20px; */
  }

  .CurrentImage {
    width: 90%;
  }

  .CloseIcon {
    transform: translate(-50%, 50%);
    bottom: 0;
    left: 50%;
    z-index: 9;
  }
  .GameContent {
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .NowPlaying {
    z-index: 99;
    width: 30rem;
    padding: 1rem 1rem;
    /* border-top-right-radius: 20px;
        border-top-left-radius: 20px; */
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .CloseIcon svg {
    transform: rotate(180deg);
  }

  .CurrentTitle p {
    margin-top: 0;
    padding: 0.75rem;
  }

  .queStatus img,
  .spectator img {
    width: 70%;
  }

  .queStatus span,
  .spectator span {
    font-size: 1.75rem;
    padding: 0;
  }

  /* video{
        border-radius: 25px;
    } */
  .video {
    /* border-radius: 25px; */
  }

  .GameScore span {
    font-size: 5rem;
    color: #000;
    -webkit-text-stroke: 1px #fff;
  }

  .Icons {
    z-index: 999;
  }
  .PlayImage {
    margin: 0 auto;
    width: 192px;
    height: 192px;
    position: relative;
    /* min-width:130px */
  }
}
@media screen and (max-width:620px) {
  .secondChancePopup{
    top: 22%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    width: 58vw;
  }
  .secondChancePopupTop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .secondChancePopupContent {  
    margin:  0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .secondChancePopupContentRowLeft{
    width: 33%;
  }
  .secondChancePopupContentRowLeft p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupContentRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 50%;
  }
  .secondChancePopupTicketIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 10vw;
    height: 3vh;
  }
  .secondChancePopupTicketAmount p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupButtonContainer{
    width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  
  }
}
@media (max-width: 599px) {
  .NowPlaying {
    width: 20rem;
  }
  .CurrentTitle p {
    font-size: 1.25rem;
    padding: 0;
  }
  .NowPlayingTitle p {
    font-size: 2rem;
  }
  .CloseIcon svg {
    font-size: 1.5rem;
  }
  .CloseIcon {
    padding: 0.5rem;
  }
  .queStatus img,
  .spectator img {
    width: 35%;
  }

  .queStatus span,
  .spectator span {
    font-size: 1.35rem;
    width: 20px;
    height: 20px;
    padding: 0;
  }
  .queStatus span {
    /* padding-top: 2px; */
  }
  .spectator span {
    /* padding-top: 1.5px; */
  }
  .GameScreen {
    width: 100%;
    margin-top: 3.5rem;
  }
  .VideoSection {
    width: 90%;
    height: 250px;
  }
  .ExtraButton button {
    width: 60%;
    padding: 5px 0;
  }
  .GameScore span {
    font-size: 3rem;
    -webkit-text-stroke: 1px #fff;
  }
}
@media screen and (max-width:560px) {
  .secondChancePopup{
    top: 15%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    width: 60vw;
  }
  .secondChancePopupTop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .secondChancePopupContent {  
    margin:  0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .secondChancePopupContentRowLeft{
    width: 33%;
  }
  .secondChancePopupContentRowLeft p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupContentRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 50%;
  }
  .secondChancePopupTicketIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 10vw;
    height: 3vh;
  }
  .secondChancePopupTicketAmount p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupButtonContainer{
    width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  
  }
}
@media screen and (max-width:490px) {
  .secondChancePopup{
    top: 18%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    width: 75vw;
  }
  .secondChancePopupTop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .secondChancePopupContent {  
    margin:  0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .secondChancePopupContentRowLeft{
    width: 33%;
  }
  .secondChancePopupContentRowLeft p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupContentRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 50%;
  }
  .secondChancePopupTicketIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 10vw;
    height: 3vh;
  }
  .secondChancePopupTicketAmount p{
    font-size: 8rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupButtonContainer{
    width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 70%);
  
  }
}
@media screen and (max-width:430px) {
  .secondChancePopup{
    top: 18%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    width: 75vw;
  }
  .secondChancePopupTop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .secondChancePopupContent {  
    margin:  0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .secondChancePopupContentRowLeft{
    width: 33%;
  }
  .secondChancePopupContentRowLeft p{
    font-size: 6rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupContentRowRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 50%;
  }
  .secondChancePopupTicketIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 12vw;
    height: 3vh;
  }
  .secondChancePopupTicketAmount p{
    font-size: 6rem;
    font-family: drumshake_italic;
    color: rgb(3, 3, 3);
    -webkit-text-stroke: 2px white;
  }
  .secondChancePopupButtonContainer{
    width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 85%);
  
  }
}
.isLogged {
  width: 100vh;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  background-color: #000;
}

.isLogged h1 {
  color: #fff;
  font-size: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Section {
  /* overflow: scroll; */
}

.Section::-webkit-scrollbar {
  display: none;
}

.Overlay {
  /* position: absolute;
    top: 0;
    left: 0; */
  /* width: 100%;
    height: 100%;
    border-radius: 50px;
    background-color: #000; */
}

/* .GameScreen .Screen{
    min-height: 45vh;
} */
.Loader {
  position: absolute;
  top: 50%;
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  height: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-evenly;
  align-items: center;
}

.LoaderDiv {
  width: 100px;
  height: 100px;
  border-bottom: 4px solid #fff;
  /* border-bottom: 0;
    border-top: 0; */
  border-radius: 100%;

  animation: loader 500ms linear infinite;
}
.ExitpopupButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 99;
}
.ExitpopupButton button {
  margin: 1.5rem 1rem;
  padding: 1rem 0rem;
  width: 30%;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 100;
  border-radius: 30px;
  font-family: myraidproregular;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Loader h1 {
  color: #fff;
  text-align: center;
}

.VideoOverlay {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
}
.VideoSection svg {
  position: absolute;
  right: 5%;
  top: 5%;
  font-size: 5rem;
  z-index: 9999;
  color: red;
}
.VideoEmptyOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.VideoEmptyOverlay h1 {
}
.videoStarting {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* background: linear-gradient( 180deg,
   rgba(27, 143, 231, 1) 0%,
    rgba(19, 192, 236, 1) 62%,
     rgba(0, 151, 231, 1) 100% ); */
  background: transparent;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 8rem;
  font-weight: 600;
  font-family: drumshake_italic;
}
.videoStarting_win {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* background: linear-gradient( 180deg,
   rgba(27, 143, 231, 1) 0%,
    rgba(19, 192, 236, 1) 62%,
     rgba(0, 151, 231, 1) 100% ); */
  background: transparent;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 8rem;
  font-weight: 600;
  font-family: drumshake_italic;
}
.videoStarting p {
  text-align: center;
}
.video .TimeoutAnimation {
  width: 75%;
  position: absolute;
  right: 0;
  z-index: 99999;
  bottom: 0;
  cursor: pointer;
}
/* @media(min-width:1065px){
    .Container,.Section{
        width: fit-content;
    }
    .ExtraGames{
        max-width: 450px;
        min-width: 450px;
    }
    .GameScreen{
        min-width: 750px;
        max-width: 750px;
        width: 40%;
        padding: 75px;
        min-height: 100vh;
        padding-top: 0rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .LeftSide ,.MLeftSide{
        min-width: 450px;
        max-width: 450px;
    }
} */
.LoaderDivSection {
  position: absolute;
  /* top: 50%; */
  width: 100%;
  height: 100%;
  /* left: 50%; */
  /* transform: translate(-50%,-50%); */
  /* width: 20rem; */
  /* background-color: #000000c9; */
}
.LoaderDivSection_win{
  position: absolute;
  /* top: 50%; */
  width: 100%;
  height: 100%;
}
.LoaderAnime {
  width: 13rem;
  position: absolute;
  top: 36%;
  left: 60%;
  transform: translate(-50%, -100%);
}
.LoaderAnime_win {
  width: 13rem;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -100%);
}
.videoStarting .LoaderDivSection p {
  font-size: 2.25rem;
  position: absolute;
  top: 36%;
  font-family: sans-serif;
  left: 60%;
  transform: translate(-50%, -60%);
  font-weight: normal;
  color: #fff;
  margin-top: 1rem;
}
.ArrowBothButtons .playImageLoader {
  position: relative;
  height: 100%;
}
/* .ArrowBothButtons .playImageLoader svg {
  z-index: 999;
}
.ArrowBothButtons .playImageLoader img {
  z-index: 9;
} */
.ArrowBothButtons .playImageLoader div {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
}
.ArrowBothButtons .playImageLoader img {
  width: 100%;
  position: relative;
  z-index: 99;
}
.ArrowBothButtons .playImageLoaderActive {
  display: none;
}
.ArrowBothButtons .playImageLoaderActive div {
  position: absolute;
}
.ArrowBothButtons .playImageLoaderActive img {
  width: 100%;
}
@media (max-width: 1065px) {
  .videoStarting {
    background: linear-gradient(
      180deg,
      rgba(27, 143, 231, 1) 0%,
      rgba(19, 192, 236, 1) 62%,
      rgba(0, 151, 231, 1) 100%
    );
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
  .LoaderDivSection {
    position: absolute;
    top: 60%;
    width: 100%;
    height: 100%;
    left: 40%;
    transform: translate(-50%, -40%);
  }
  .LoaderDivSection_win{
    position: absolute;
    top: 30%;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .videoStarting_win {
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
}

.PlayIcon button svg {
  font-size: 5rem;
  color: #fff;
}
.PrizeMove {
  z-index: 999999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  height: 100% !important;
}
.PrizeMove img {
  width: 100%;
  height: 100%;
  border-radius: 75px;
  padding: 12px;
}
.video .gameGuideOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.video .gameGuideOverlay img {
  width: 100%;
  height: 100%;
  border: 5px solid #fff;
  border-radius: 50px;
}
.video .GameFullOverlay {
  width: 100%;
  position: fixed;
  /* background-color: red; */
  height: 100vh;
  z-index: 999;
}
.OverlayBg,
.DeacOverlayBg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
  z-index: 9;
}
.DeacOverlayBg {
  z-index: 99999;
}
/* .Image{
    width: 100%;
    height: 65%;
}
.Image img{
    width: 100%;
    height: 100%;
    border-top-right-radius: 2.26rem;
    border-top-left-radius: 2.26rem;
}
.Details{
    margin-top: 0.56rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 85%;
    margin: 0.56rem auto;
    margin-bottom: 0;
    height: 35%;
} */
.Image {
  height: 65%;
}
.Image img {
  height: 100%;
}

.MLeftSide {
  display: none;
}
@media (max-width: 1065px) {
  .MLeftSide {
    display: flex;
  }
  .LeftSide {
    display: none;
  }
  .videoStarting .LoaderDivSection p {
    font-size: 3.5rem;
    margin-top: 4rem;
  }
}
.HowToPlayOverlay {
  width: 100vw;
  height: 135vh;
  position: fixed;
  z-index: 9;
}
.howToPlay {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  z-index: 99999;
  top: 0;
  transform: translateY(2%);
}
.PrizeMoveOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
}
@media (max-width: 765px) {
  .video .gameGuideOverlay img {
    border-radius: 30px;
    border-width: 3px;
  }
  .PlayImage {
    margin: 0 auto;
    width: 192px;
    height: 192px;
    position: relative;
    /* min-width:130px */
  }
}
@media (max-width: 1065px) {
  .video .gameGuideOverlay img {
    border-radius: 30px;
    /* border-width: 3px; */
  }
  .VideoOverlay {
    transform: translate(-5%, -50%);
  }
}
.AllVideoSection {
  width: 100%;
}
.ExtraButton {
  display: flex;
  justify-content: center;
}
@media (max-width: 1065px) {
  .GameScreen .Screen {
    position: relative;
  }
  .PlayImage {
    margin: 0 auto;
    width: 250px;
    height: 250px;
    position: relative;
    /* min-width:130px */
  }
}
.kickoutPopupAll {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999999;
}
.KickoutOverlay {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #00000034;
}
.WareHousekickoutPopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999999;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 30px;
  background-color: #1b8fe7;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
}
.kickoutPopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999999;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 30px;
  background-color: #1b8fe7;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 300px;
}
.KickoutBtn {
  width: 100%;
  position: relative;
  z-index: 999;
  padding: 0rem 2rem;
}
.KickoutBtn {
  display: flex;
  flex-direction: row;
}
.KickoutBtn button {
  width: 100%;
}

.KickoutBtn button {
  margin: 1.5rem 0.5rem;
  padding: 1rem 0rem;
  width: 100%;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
  font-family: myraidproregular;
}
.popupText {
  margin-top: 15%;
  position: relative;
  z-index: 99;
  padding: 0rem 2rem;
}

.KickoutMessage {
  margin-top: 17.5%;
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.25rem;
  font-weight: 500;
}
@media (max-width: 712px) {
  .videoStarting .LoaderDivSection p {
    font-size: 3rem;
    padding-top: 5px;
    margin-top: 5rem;
  }
  .ExtraGames {
    width: 90%;
  }
  .AllGames {
    width: 99%;
  }
  .LastWin {
    margin: 0 auto;
    width:80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Camera{
    width: 80%;
  }
  
}
@media (max-width: 599px) {
  .LoaderAnime {
    width: 8rem;
  }
  .LoaderAnime_win {
    width: 8rem;
  }
  .PrizeReset {
    width: 5rem;
  }
  .PrizeReset button {
    width: 6rem;
    margin: 5px 0;
  }
  .Camera button,
  .LastWin button {
    width: 8rem;
  }
  .Report button {
    width: 45%;
  }
  .videoStarting .LoaderDivSection p {
    font-size: 1.5rem;
    padding-top: 5px;
    margin-top: 1rem;
  }
  .howToPlay {
    width: 95%;
  }
  .VideoOverlay {
    transform: translate(-5%, -50%);
  }
  .PlayImage {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    position: relative;
    /* min-width:130px */
  }
  .Controls {
    width: 100%;
  }
  .ExtraGames {
    width: 100%;
  }
  .AllGames {
    width: 96%;
  }
}
.BgStaticImaage {
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  min-width: 12rem;
  height: 100%;
  width: 100%;
  z-index: 0; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  /* min-width: 12rem; */
  height: 100%;
  width: 100%;
  z-index: 0;
}
.BgStaticImaage img {
  width: 100%;
  height: 100%;
}
/* .PlayImage{
  -webkit-user-select: none;
} */
.ArrowBothButtons {
  height: 100%;
}
button {
  -webkit-tap-highlight-color: transparent;
}
