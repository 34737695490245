.Container {
  width: 100%;
  /* height: 90vh; */
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-image: url("../../assests/Login BG.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  position: relative;
}
.Login {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.Left {
  width: auto;
  height: 100%;
}
.Left img {
  width: 100%;
  height: 100%;
}
.Right {
  width: 56rem;
  /* height: 100%; */
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
}
.e4,
.Password {
  width: 100%;
}
.TitleDiv {
  width: 70%;
  border-bottom: 0.22rem solid lightgray;
  margin-bottom: 5.68rem;
}
.ForgotPassword {
  text-align: center;
  color: #3b82f6;
  /* color: rgb(201, 0, 0); */
  font-size: 2rem;
  font-weight: 600;
  margin: 2rem 0;
  background-color: transparent;
  border: none;
  outline: none;
}
.Title {
  font-size: 3rem;
  margin: 1.7rem;
  text-align: center;
  font-weight: 600;
}
.Btns,
.Btns a {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Btns a {
  width: 100%;
}
.PasswordError {
}
.LoginBtn,
.RegisterBtn {
  /* font-family: "Poppins";
    width: 70%;
    text-align: center;
    font-size: 3.5rem;
    text-transform: uppercase; */

  /* height: 9rem;
    border-radius: 5.68rem;
    margin:.5rem 0;
    background-color: #1589e1;
    color: #fff;
    border:0.56rem solid #fff;
    cursor: pointer;
    font-weight: 500; */
}
.LoginBtn,
.RegisterBtn {
  font-family: "Poppins";
  width: 50%;
  font-size: 3rem;
  margin-top: 1.13rem;
  height: 7rem;
  padding: 1.13rem;
  text-align: center;
  border-radius: 4rem;
  margin: 1.13rem 0;
  color: #fff;
  border: none;
  background-color: #3b82f6;
  cursor: pointer;
}
/* .Right a{
    width: 100%;
} */
.RegisterBtn {
  /* width: 40%; */
  background-color: #939393;
  color: #fff;
}
.email label,
.Password label {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
}
.EmailInput {
  font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  background-color: #fff;
  height: 7.5rem;
  /* height: 5.68rem; */
  padding: 1.13rem 2.26rem;
  text-align: center;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  outline: none;
}
.EmailInput::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  color: #bdbdbd;
}
.EmailInput {
  text-align: center;
}
/* input:-webkit-autofill, 
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
} */
.loginError {
  text-align: left;
  margin-right: auto;
  font-size: 2.02rem;
  color: red;
  font-weight: 600;
}
.email {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.Password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Password {
  font-family: "Poppins";
  width: 100%;

  background-color: #fff;
  height: 7.5rem;
  /* height: 5.68rem; */
  padding: 0rem 2.26rem;
  text-align: center;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
}
.passwordInput::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  color: #bdbdbd;
}
.info {
  width: 9%;
  display: flex;
  align-items: center;
  margin: auto;
}
.info img {
  width: 100%;
  cursor: pointer;
}
.eye {
  width: 12%;
  display: flex;
  align-items: center;
  margin: auto;
}
.eye img {
  width: 100%;
  cursor: pointer;
}
.passwordInput {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "Poppins";

  font-size: 3rem;
  background-color: #fff;
  /* height: 5.68rem; */
  text-align: center;

  /* border:.25rem solid #333333c1; */
}
.popup {
  width: 45rem;
  height: 45rem;
  padding: 50px;
  border-radius: 15px;
  border: 10.5px solid #949393;
  position: relative;
  background-color: #0a84fe;
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.popup_forgot {
  width: 45rem;
  height: 35rem;
  padding: 50px;
  border-radius: 15px;
  border: 10.5px solid #949393;
  position: relative;
  background-color: #0a84fe;
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content {
  margin: 10% 0;
}
.forgotcontent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  margin-top: 25%;
}
.content ul {
  display: flex;
  flex-direction: column;
  list-style: circle;
  font-size: 1.75rem;
}
.content ul p {
  color: #fff;
  padding: 7px 0;
  text-align: center;
}
.content ul li {
  margin: 7px 0px;
  color: #fff;
}
.action {
  /* margin: auto; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Forgotaction {
  /* margin: auto; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.action button {
  margin: auto;
  width: 75%;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  outline: none;
  padding: 10px 0;
  font-size: 2rem;
}
.Forgotaction button {
  margin: 1.5rem;
  width: 40%;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  outline: none;
  padding: 12px 0;
  font-size: 2.25rem;
}
.image {
  width: 45%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image img {
  width: 100%;
}
.forgotEmail p {
  font-size: 2.25rem;
  color: #fff;
  line-height: 30px;
  text-align: center;
  font-weight: 300;
}
.forgotInput {
  width: 100%;
  /* height: 100%; */
  margin-top: 10px;
}
.forgotInput input {
  width: 100%;
  height: 5.5rem;
  font-size: 2.75rem;
  border: 2px solid #949393;
  background-color: #fff;
  outline: none;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  color: #000;
}
.forgotInput input::-webkit-input-placeholder {
  color: #33333d7c;
  text-align: center;
  text-transform: uppercase;
}
.PasswordError {
  color: red;
}
@media (min-width: 510px) and (max-width: 1056px) {
  .Login {
    justify-content: center;
  }
  .popup {
    width: 60%;
    left: 50%;
  }
  .content {
    margin: 14% 0;
  }
  .forgotEmail p {
    font-size: 2.75rem;
  }

  .EmailInput {
    height: 5.11rem;
    font-size: 2.25rem;
    font-weight: 500;
    text-align: center;
  }
  .Password {
    height: 5.11rem;
    font-weight: 500;
    text-align: center;
  }
  .passwordInput {
    font-size: 2.25rem;
  }

  .popup_forgot {
    width: 45rem;
    height: 35rem;
    padding: 50px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 59%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 25%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 12px 0;
    font-size: 2.25rem;
  }
}
@media (max-width: 500px) {
  .Container {
    height: auto;
    padding: 4rem 0;
  }
  .Left {
    display: none;
  }
  .Right {
    /* height: 100%; */
    width: 100%;
  }
  .TitleDiv {
    /* margin-bottom: 2.84rem; */
  }
  .Title {
    /* font-size: 2.84rem; */
  }
  /* .email {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  } */
  .EmailInput label,
  .PasswordInput label {
    /* font-size: 1.36rem; */
  }
  .ForgotPassword {
    /* font-size: 1.36rem;
        margin-bottom: 1.70rem; */
    font-size: 1.36rem;
    margin: 1rem;
  }
  .LoginBtn,
  .RegisterBtn {
    /* height: 5.11rem;
        font-size: 1.70rem; 
        margin: 0.56rem; */
  }
  /* .email {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  } */

  /* .passwordInput::-webkit-input-placeholder {
    font-size: 2rem;
    font-weight: 500;
  } */
  .Btns button {
    font-size: 2rem;
    font-weight: 500;
    height: 5.11rem;
  }
  .popup {
    width: 90%;
    padding: 30px;
  }
  .info {
    text-align: center;
    align-items: center;
    margin: 0;
    width: 10%;
  }
  .eye {
    width: 13%;
  }
  .EmailInput {
    height: 5.11rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
  .Password {
    height: 5.11rem;
    font-weight: 500;
    text-align: center;
  }
  .passwordInput {
    font-size: 2rem;
  }
  .popup_forgot {
    width: 45rem;
    height: 35rem;
    padding: 50px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 59%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 25%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 12px 0;
    font-size: 2.25rem;
  }
}
@media (max-width: 1065px) {
  .Container {
    height: auto;
    padding: 6rem 0;
    background: #fae863;
  }
  .Right {
    -webkit-box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 1);
  }
  .EmailInput {
    height: 9rem;
    font-size: 3.25rem;
    font-weight: 500;
    text-align: center;
  }
  .Password {
    height: 9rem;
    font-weight: 500;
    text-align: center;
  }
  .passwordInput {
    font-size: 3.25rem;
  }
  .popup_forgot {
    width: 45rem;
    height: 35rem;
    padding: 50px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 59%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 25%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 12px 0;
    font-size: 2.25rem;
  }
  .passwordInput::-webkit-input-placeholder {
    font-weight: 500;
  }
}
@media (max-width: 765px) {
  .popup {
    width: 32rem;
    left: 50%;
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
  }
  .image {
    width: 35%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .forgotEmail p {
    font-size: 1.75rem;
    line-height: 20px;
  }
  .content {
    margin: 0% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    /* padding: 9rem 0px; */
    margin-top: 15%;
  }
  .action button {
    margin: 1rem;
    width: 85%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 8px 0;
    font-size: 1.75rem;
  }

  .EmailInput {
    height: 6.11rem;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
  }
  .Password {
    height: 6.11rem;
    font-weight: 500;
    text-align: center;
  }
  .passwordInput {
    font-size: 2.5rem;
    font-weight: 500;
  }
  .popup_forgot {
    width: 35rem;
    height: 25rem;
    padding: 15px;
    border-radius: 15px;
    left: 50%;
  }
  .forgotcontent {
    margin-top: 15%;
  }

  .forgotInput input {
    font-size: 1.75rem;
    height: 4rem;
  }
  .Forgotaction button {
    margin: 2rem 1rem;
    width: 35%;
    padding: 10px 0;
    font-size: 1.75rem;
  }
}
@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .popup {
    width: 45rem;
    height: 45rem;
    padding: 50px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 59%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
  .image {
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content {
    margin: 10% 0;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2rem;
  }
  .content ul p {
    color: #fff;
    padding: 7px 0;
    text-align: center;
  }
  .content ul li {
    margin: 7px 0px;
    color: #fff;
  }
  .action {
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .popup_forgot {
    width: 45rem;
    height: 35rem;
    padding: 50px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 59%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 25%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 12px 0;
    font-size: 2.25rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .popup {
    width: 45rem;
    left: 50%;
    height: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4rem;
  }
  .image {
    width: 40%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content {
    margin: 0% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    /* padding: 9rem 0px; */
    margin-top: 15%;
  }
  .content ul {
    font-size: 2.5rem;
  }
  .action button {
    margin: 1rem;
    width: 85%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 8px;
    font-size: 2.5rem;
  }
  .popup_forgot {
    width: 46rem;
    height: 35rem;
    padding: 25px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 15%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 15px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px 0;
    font-size: 2.25rem;
  }
}
@media screen and (max-device-width: 1400px) and (orientation: landscape) {
  .popup {
    left: 45%;
  }
  .popup_forgot {
    width: 45rem;
    height: 35rem;
    padding: 25px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 15%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px 0;
    font-size: 2.25rem;
  }
}
@media screen and (max-device-width: 1200px) and (orientation: landscape) {
  .popup {
    left: 36.5%;
  }
  .popup_forgot {
    width: 45rem;
    height: 35rem;
    padding: 25px;
    left: 37%;
  }
  .forgotcontent {
    margin-top: 22%;
  }
  .forgotInput {
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.5rem;
    width: 40%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px 0;
    font-size: 2.25rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .popup {
    width: 55rem;
    left: 50%;
    height: 48rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 6rem;
  }
  .image {
    width: 40%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content {
    margin: 0% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    /* padding: 9rem 0px; */
    margin-top: 17%;
  }
  .content ul {
    font-size: 2.75rem;
  }
  .action button {
    margin: 1rem;
    width: 75%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 12px 0;
  }
  .popup_forgot {
    width: 50rem;
    height: 35rem;
    padding: 25px;
    border-radius: 15px;
    border: 10.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 15%;
  }
  .forgotInput {
    width: 100%;
    /* height: 100%; */
    margin-top: 10px;
  }
  .forgotInput input {
    font-size: 2rem;
  }
  .Forgotaction button {
    margin: 1.25rem;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 15px 0;
    font-weight: 600;
    font-size: 2.5rem;
  }
}
.warehousePopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999;
  top: 50%;
  left: 50%;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 20px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
  height: 22rem;
}
.warehousePopupText {
  margin-top: 10%;
}
.warehousePopupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.25rem;
  font-weight: 100;
}
.popupOverlaySection {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 9999;
}
.ResendpopupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  /* width: 50%; */
}
.ResendpopupImage img {
  width: 100%;
}