.Conatiner {
  width: 100%;
  height: 100vh;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ResetPassword {
  padding: 2.35rem;
  border-radius: 1.7rem;
  width: 56rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.ResetPassword form {
  padding: 2.35rem;
  border-radius: 1.7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.password {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  padding: 0rem 2.26rem;
  /* padding: 1.13rem 2.26rem; */
  text-align: left;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  height: 7.5rem;
}
 .input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 3rem;
  background-color: #fff;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.info {
  width: 10%;
  display: flex;
  align-items: center;
  margin: auto;
}
.info img {
  width: 100%;
  cursor: pointer;
}
.input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins";
  /* color: #333333d1; */
  color: #bdbdbd;
}
.eyeIcon {
  width: 10%;
  cursor: pointer;
}
.AgeRestrict {
  color: red;
  font-size: 1.81rem;
  margin: 2.84rem 0px;
  margin-right: auto;
  margin-top: 0;
  text-align: center;
}
.criteriaCheck{
  color: red;
  font-size: 1.81rem;
}
.CheckPassword {
  text-align: left;
  width: 100%;
}
.formBtn {
  font-family: "Poppins";
  width: 75%;
  font-size: 3rem;
  margin-top: 1.13rem;
  height: 7rem;
  padding: 1.13rem;
  text-align: center;
  border-radius: 4rem;
  margin: 1.13rem 0;
  color: #fff;
  border: none;
  text-transform: uppercase;
  background-color: #3b82f6;
  cursor: pointer;
}
.popup {
  width: 38rem;
  height: 40rem;
  padding: 35px;
  border-radius: 15px;
  border: 7.5px solid #949393;
  position: relative;
  background-color: #0a84fe;
  position: absolute;
  top: 50%;
  z-index: 999;
  left: 50%;
  transform: translate(-175%, -50%);
}
.image {
  width: 40%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image img {
  width: 100%;
}
.content {
  margin: 2rem 0;
}
.content ul {
  display: flex;
  flex-direction: column;
  list-style: circle;
  font-size: 2rem;
}
.content ul p {
  color: #fff;
  padding: 8px;
  text-align: center;
}
.content ul li {
  margin: 7px 0px;
  color: #fff;
}
.action {
  /* margin: auto; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.action button {
  margin: auto;
  width: 75%;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  outline: none;
  padding: 10px;
  font-size: 2rem;
}
.Success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.successImage {
  width: 10rem;
  height: 10rem;
}
.successImage img {
  width: 100%;
  height: 100%;
}
.verification {
  display: flex;
  align-items: center;
  margin: 2rem;
}
.verification p {
  font-size: 2.5rem;
  font-weight: 500;
}

@media screen and (max-device-width: 1024px){
    .popup {
        width: 45rem;
        height: 43rem;
        padding: 50px;
        border-radius: 15px;
        border: 7.5px solid #949393;
        position: relative;
        background-color: #0a84fe;
        position: absolute;
        top: 50%;
        z-index: 999;
        left: 50%;
        transform: translate(-50%, -50%);
      }
}
@media (max-width: 767px) {
  .ResetPassword {
    padding: 1.25rem;
    border-radius: 1.7rem;
    width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  .password {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: "Poppins";
    width: 100%;
    font-size: 2rem;
    padding: 0rem 2.26rem;
    /* padding: 1.13rem 2.26rem; */
    text-align: left;
    border-radius: 1.13rem;
    margin: 1.13rem 0;
    border: 0.25rem solid #606060;
    height: 5.5rem;
  }
  .input{
    font-size: 2rem;
  }
  .formBtn{
    font-family: "Poppins";
    width: 75%;
    font-size: 2rem;
    margin-top: 1.13rem;
    height: 5rem;
    padding: 1.13rem;
    text-align: center;
    border-radius: 4rem;
    margin: 1.13rem 0;
    color: #fff;
    border: none;
    text-transform: uppercase;
    background-color: #3b82f6;
    cursor: pointer;
  }
  .successImage{
    width: 8rem;
    height: 8rem;
  }
  
.popup {
    width: 35rem;
    height: 40rem;
    padding: 35px;
    border-radius: 15px;
    border: 7.5px solid #949393;
    position: relative;
    background-color: #0a84fe;
    position: absolute;
    top: 50%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .image {
    width: 35%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .image img {
    width: 100%;
  }
  .content {
    margin: 1rem 0;
  }
  .content ul {
    display: flex;
    flex-direction: column;
    list-style: circle;
    font-size: 2rem;
  }
  .content ul p {
    color: #fff;
    padding: 8px;
    text-align: center;
  }
  .content ul li {
    margin: 7px 0px;
    color: #fff;
  }
  .action {
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .action button {
    margin: auto;
    width: 75%;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    outline: none;
    padding: 10px;
    font-size: 2rem;
  }
}