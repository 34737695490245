.container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url("../../assests/Artboard\ 3-7\ Background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  font-family: "myraidproregular";
}
.msg_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  background: #ffff;
}
.deleteSection {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.headerImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 1rem 0;
}
.headerImage img {
  width: 100%;
  height: 100%;
}
.inputSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.inputHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.inputHeader h1 {
  font-size: 2.5rem;
  font-weight: 600;
}
.inputDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  margin: 1rem 0;
}
.inputText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 1rem 0;
}
.inputText p {
  font-size: 2rem;
  padding-left: 2rem;
}
.inputEmail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.inputEmail input {
  width: 65%;
  font-size: 2.25rem;
  background-color: #fff;
  height: 5rem;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  outline: none;
  text-align: center;
}
.passwordInput {
  width: 65%;
  background-color: #fff;
  height: 5rem;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #606060;
  outline: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
.passwordInput input {
  width: 85%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 2.25rem;
  background-color: #fff;
  text-align: center;
  margin-left: 35px;
}
.inputLogo {
  width: 10%;
  display: flex;
  align-items: center;
  margin: auto;
}
.inputLogo img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.inputButtons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}
.inputButtons button {
  width: 30%;
  font-size: 2rem;
  text-align: center;
  border-radius: 5rem;
  color: #fff;
  border: none;
  background-color: #3b82f6;
  cursor: pointer;
  padding: 1rem;
  text-transform: uppercase;
  margin: 0 1rem;
}
.inputButtons button:nth-child(2) {
  background: #939393;
}

.validationMessage p {
  font-size: 2rem;
  font-weight: 400;
  color: red;
}

.msg_box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  /* height: 42vh; */
  background: #ffff;
}
.deleteSectionConfirm {
  width: 100%;
  height: 100%;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.delHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.delHeader h1 {
  font-size: 2.75rem;
  font-weight: 700;
}
.confirmSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0.75rem 0;
}
.confirmInputText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  margin: 1rem 0;
}
.confirmInputText p {
  font-size: 2.5rem;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}
.checkbox input {
  height: 20px;
  margin: 0 1rem;
  width: 20px;
  border: 3px solid #151111;
  cursor: pointer;
}

.checkbox p {
  font-size: 2.5rem;
}

.inputButtons2 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 1rem 0;
  margin: 1rem 0;
}
.inputButtons2 button {
  background-color: #e60000;
  border: none;
  border-radius: 5rem;
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  margin: 0 1rem;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  width: 30%;
}
.inputButtons2 button:nth-child(2) {
  background: #939393;
}

/* Media query  1065px  */

@media (max-width: 1065px) {
  .container {
    width: 100%;
    height: 85vh;
    background-image: url("../../assests/Landing\ Page\ BG\ Mobile.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .msg_box {
    width: 95%;
  }
  .deleteSection {
    padding: 2rem 2.5rem;
  }

  .inputSection {
    margin: 1.5rem 0;
  }

  .inputHeader h1 {
    font-size: 3.25rem;
  }

  .inputText p {
    font-size: 3rem;
  }
  .inputEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .inputEmail input {
    width: 65%;
    font-size: 2.75rem;
    height: 5.75rem;
    margin: 1.25rem 0;
  }
  .passwordInput {
    width: 65%;
    height: 5.75rem;
    margin: 1.25rem 0;
  }
  .passwordInput input {
    font-size: 2.75rem;
  }

  .inputButtons button {
    width: 35%;
    font-size: 2.75rem;
  }

  .validationMessage p {
    font-size: 3rem;
    font-weight: 400;
    color: red;
  }

  .msg_box2 {
    width: 95%;
  }
  .deleteSectionConfirm {
    padding: 2rem 2.5rem;
  }
  .headerImage {
    width: 25%;
    margin: 0;
  }
  .confirmSection {
    margin: 1.5rem 0;
  }

  .delHeader h1 {
    font-size: 3rem;
  }

  .confirmInputText {
    margin: 1.25rem 0;
  }
  .confirmInputText p {
    font-size: 2.75rem;
  }

  .checkbox p {
    font-size: 2.75rem;
  }

  .inputButtons2 {
    margin: 1.25rem 0;
  }
  .inputButtons2 button {
    width: 32%;
    font-size: 2.25rem;
    padding: 1.25rem;
  }
  .inputLogo {
    width: 7%;
  }
  .checkbox input {
    width: 25px;
    height: 25px;
    margin: 0 1.5rem;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    height: 85vh;
    background-image: url("../../assests/Landing\ Page\ BG\ Mobile.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .msg_box {
    width: 95%;
  }
  .deleteSection {
    padding: 2rem;
  }

  .inputSection {
    margin: 1.5rem 0;
  }

  .inputHeader h1 {
    font-size: 2.25rem;
  }
  .inputDetail {
    padding: 0.5rem 0;
    margin: 0.5rem 0;
  }
  .inputText {
    margin: 0.5rem 0;
  }
  .inputText p {
    font-size: 2rem;
  }
  .inputEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
  }
  .inputEmail input {
    width: 100%;
    font-size: 2rem;
    height: 4.5rem;
    margin: 1rem 0;
  }
  .passwordInput {
    width: 85%;
    height: 4.5rem;
    margin: 1rem 0;
  }
  .passwordInput input {
    font-size: 2rem;
    width: 100%;
    margin-left: 0;
  }
  .inputLogo {
    width: 10%;
    margin: 0 0.5rem;
  }
  .inputButtons {
    width: 75%;
  }
  .inputButtons button {
    width: 50%;
    font-size: 1.5rem;
  }

  .validationMessage p {
    font-size: 2rem;
    font-weight: 400;
    color: red;
  }

  .msg_box2 {
    width: 95%;
  }
  .deleteSectionConfirm {
    padding: 2rem;
  }
  .headerImage {
    width: 40%;
    margin: 0;
  }
  .confirmSection {
    margin: 1.5rem 0;
  }

  .delHeader h1 {
    font-size: 2.25rem;
  }

  .confirmInputText {
    margin: 1rem 0;
    width: 100%;
  }
  .confirmInputText p {
    font-size: 2rem;
  }
  .checkbox input {
    width: 15px;
    height: 15px;
    margin: 0 1.25rem;
  }

  .checkbox p {
    font-size: 2rem;
  }

  .inputButtons2 {
    padding: 0.75rem 0;
    margin: 0.75rem 0;
  }
  .inputButtons2 button {
    width: 35%;
    font-size: 1.5rem;
  }
}

@media (max-width: 551px) {
  .inputText p {
    font-size: 1.75rem;
  }

  .inputButtons {
    width: 70%;
  }
  .checkbox {
    margin: 1rem 0;
    margin-right: 10%;
  }
}
