*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  /* position: relative; */
  overflow: hidden;
  background-color:#2D2D2D;
}
button { -webkit-tap-highlight-color: transparent; }
a{
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
li{
  cursor: pointer;
}
.OT_publisher, .OT_subscriber{
  position: static !important;
  width: 100% !important;
  height: auto !important;
  overflow: hidden;
}
.OT_publisher .OT_video-element, .OT_subscriber .OT_video-element {
  display: block;
  /* position: absolute; */
  border-radius: 50px;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  border: 6px solid #fff;
  transform: translateZ(0)
}

.OT_publisher .OT_video-element, .OT_subscriber .OT_video-element {
  display: block;
  /* position: absolute; */
  /* width: 100%; */
  /* height: 100%; */
  transform-origin: 0 0;
}

.OT_publisher .OT_video-element, .OT_subscriber .OT_video-element {
  display: block;
  /* position: absolute; */
  border-radius: 50px;
  /* width: 100%; */
  /* height: 100%; */
  transform-origin: 0 0;
  border: 6px solid #fff;
  transform: translateZ(0);
}

@media(max-width:1065px){
  .OT_publisher .OT_video-element, .OT_subscriber .OT_video-element {
    border-radius: 30px ;
    border-width:3px;
  }
}
/* @media(min-width:1065px){
  .App{
    background-color: #2d2d2d;
    overflow: scroll;
  }
} */
::-webkit-scrollbar{
  display: none;
}
.landscapeMode{
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fff;
  font-size: 2rem;

}
.landscapeMode p{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #000;

}
.PotraitOrientation{
  display: flex;
  flex-direction: column;
}
@media screen and (min-device-width: 1024px){
  .PotraitOrientation{
    flex-direction: Column;
  }
  .LandscapeOrientation{
    display: none;
  }
}
@media screen and (max-device-width: 1024px) and (orientation: portrait) {
  .PotraitOrientation{
    display: flex;
  }
  .LandscapeOrientation{
    display: none;
  }
}
@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .PotraitOrientation{
    display: none;
  }
  .LandscapeOrientation{
    display: flex;
  }
}