.Container {
  width: 100%;
  height: 100%;
  position: relative;
}
.MHome {
  display: none;
}
.Transition,
.About,
.Watch,
.Support {
  width: 100%;
  /* height: 100vh; */
}
.About {
  margin-top: -5.5%;
}
.Transition div {
  /* height: 100%; */
}
.Support {
  background-image: url("../../assests/Support City Background.png");
  background-size: cover;
}
.MSupport {
  background-image: url("../../assests/Support City Background.png");
  background-size: cover;
  background-position: left;
}
.ContactSection {
  width: 40%;
  color: #fff;
  font-size: 4rem;
  font-weight: 600;
}
.SupportContent {
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.Image {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Contact {
  width: 100%;
}
.Contact img {
  width: 100%;
  height: 100%;
}
.LeftIcons,
.RightIcons,
.WLeftIcons,
.WRightIcons {
  position: absolute;
  top: 50%;
  transform: translateY(0%);
  z-index: 99;
}
.LeftIcons,
.RightIcons {
  display: none;
}
.Slider {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
}
.Slider img {
  width: 100%;
  height: auto;
  /* object-fit: ; */
}
.LeftIcons,
.WLeftIcons {
  left: 0;
}
.RightIcons,
.WRightIcons {
  right: 0;
}
.RightIcons span,
.LeftIcons span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.5rem;
  height: 10rem;
  background-color: red;
  border-radius: 20px;
  border: 0.3rem solid #fff;

  /* vertical-align: middle; */
  margin: auto;
}
.RightIcons span svg,
.LeftIcons span svg {
  font-size: 4rem;
  color: #fff;
}
.WRightIcons button,
.WLeftIcons button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.5rem;
  height: 10rem;
  background-color: transparent;
  border-radius: 20px;
  border: none;
  outline: none;
  /* border:0.3rem solid #fff; */

  /* vertical-align: middle; */
  margin: auto;
}
.WRightIcons button svg,
.WLeftIcons button svg {
  font-size: 4rem;
  color: #fff;
}
.Slidernav {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* transform: translateY(100%); */
  /* top: 100%; */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.CircleNav .Active {
  width: 3.5rem;
  height: 3.5rem;
  margin: 5px;
  /* font-size: 100px; */
  border-radius: 50%;
  background-color: blue;
  border: 3px solid #fff;
}
.CircleNav .NonActive {
  width: 3.5rem;
  height: 3.5rem;
  margin: 5px;
  /* font-size: 100px; */
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 3px solid #fff;
}
.Transition {
  position: relative;
}
.Crane {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}
.Watch img {
  width: 100%;
}
.Ticket {
  transition: all 0.25s ease-in-out;
}
.Ticket a,
.TicketTop a {
  position: -webkit-fixed;
  position: fixed;
  z-index: 9999;
  top: 80px;
  right: 10px;
  min-width: 15rem;
  max-width: 30rem;
  height: 90px;
  background-color: red;
  border: 10px solid #949393;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.25s ease-in-out;
}
.TicketTop a {
  top: 0;
  transition: all 0.25s ease-in-out;
}
.Ticket a .TicketIcon,
.TicketTop a .TicketIcon {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
}
.TicketText {
  /* overflow: auto; */
  width: 50%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.TicketText img {
  width: 135%;
}
.ContactSection p {
  /* text-align: left; */
  font-family: drumshake_italic;
  font-size: 15rem;
  margin: 0;
  text-align: center;
  font-weight: 500;
}
.ContactSection .StrokeContact {
  color: #000;
  font-size: 7rem;
  font-weight: 900;
  line-height: 0px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 0.05rem;
  margin-bottom: 10rem;
}
.WLeftIcons .Disable svg,
.WRightIcons .Disable svg {
  color: lightgray;
  cursor: not-allowed;
}
.AppStore {
  width: 20%;
  position: absolute;
  top: 0;
  z-index: 999;
  left: 40%;
  transform: translate(-50%, -50%);
}
.PlayStore {
  width: 20%;
  height: 15.25%;
  position: absolute;
  top: 0;
  z-index: 999;
  left: 60%;
  transform: translate(-50%, -50%);
}
.PlayBtn {
  position: absolute;
  left: 50%;
  bottom: 45%;
  transform: translate(-50%, 125%);
  width: 10.5%;
  z-index: 999;
}
.PlayBtn img {
  width: 100%;
  cursor: pointer;
}
.Crane,
.MCrane {
  /* width: 100vw;
    height: 75vh; */
  /* position: relative; */
  position: absolute;
  z-index: 99;
  left: 50%;
  width: 80%;
  top: 0;
  transform: translateX(-23%) !important;
}
.MCrane {
  transform: translateX(15%) !important;
  left: 0;
}
.Crane div {
  /* position: absolute; */
  /* position: relative; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
}
.Crane div svg {
  /* width: 60% !important; */
}
.TicketIcon img {
  width: 80%;
}

@media (max-width: 1065px) {
  .ContactSection {
    width: 100%;
  }
  .Home {
    display: none;
  }
  .MHome {
    display: flex;
    flex-direction: column;
  }
  .Mtransition,
  .MAbout,
  .MWatch,
  .MSupport {
    height: 100vh;
    width: 100%;
    position: relative;
    /* margin-top: -9%; */
  }
  .Mtransition {
    margin-top: -1%;
  }
  .MAbout {
    margin-top: -7%;
    height: auto;
  }
  .MTransitionBg {
    width: 100%;
    height: 100%;
  }
  .MTransitionBg img {
    width: 100%;
    height: 100%;
  }
  .MTransitionContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .MPlayBtn {
    width: 55%;
  }
  .MPlayBtn img {
    width: 100%;
  }
  .MAppStore {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .appStore img {
    width: 100%;
  }
  .playStore img {
    width: 100%;
  }
  .appStore {
    width: 250px;
  }
  .playStore {
    width: 260px;
  }
  /* .MAppStore img{
        width: 100%;
    } */
  .MSupportBg {
    width: 100%;
  }
  .MWatch {
    width: 100%;
    height: 100vh;
  }
  .MWatchLandscape {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .MWatch img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .MSupportBg img {
    width: 100%;
  }
  .SupportContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .ContactSection p {
    font-size: 20rem;
  }
  .ContactSection .StrokeContact {
    font-size: 8.5rem;
    font-weight: 600;
  }
  .Contact {
    margin-top: 30rem;
  }
}
@media (max-width: 820px) {
  .appStore {
    width: 250px;
  }
  .playStore {
    width: 260px;
  }
}
@media (max-width: 768px) {
  .ContactSection .StrokeContact {
    margin-top: 0;
  }
  .Contact {
    margin-top: 20rem;
  }
}
@media (max-width: 767px) {
  .Slider img {
    height: 100%;
    object-fit: cover;
  }
  .ContactSection p {
    /* text-align: left; */
    font-family: drumshake_italic;
    font-size: 19rem;
    margin: 0;
    line-height: 50px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 3px;
  }
  .ContactSection .StrokeContact {
    font-size: 8rem;
    margin-top: 5rem;
    letter-spacing: 1px;
  }

  .Ticket a,
  .TicketTop a {
    position: -webkit-fixed;
    position: fixed;
    z-index: 999;
    top: 8%;
    right: 3%;
    min-width: 10rem;
    max-width: 22rem;
    height: 64px;
    background-color: red;
    border: 7px solid #949393;
    border-radius: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.25s ease-in-out;
  }
  .TicketTop a {
    top: 2%;
    /* right: 0; */
  }
  .SupportContent {
    align-items: normal;
  }
  .ContactSection {
    margin-top: 5rem;
  }
  .Contact {
    margin-top: 30rem;
  }
  .MAppStore {
    width: 80%;
  }
}
@media (max-width: 712px) {
  .appStore {
    width: 250px;
  }
  .playStore {
    width: 260px;
  }
}
.Support {
  height: 100vh;
}

.popup {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b8fe7;
  border: 1rem solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40rem;
}
.Overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
}
.popupImage {
  width: 40%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popupImage img {
  width: 100%;
  object-fit: contain;
}
.popupText {
  /* width: 100%; */
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.popupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  width: 300px;
  font-weight: 500;
  /* word-wrap: break-word; */
  line-height: 25px;
}
.popupButton button {
  margin: 1.5rem;
  width: fit-content;
  padding: 1rem 3rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
}
.ReportPopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  width: 100%;
}
.ReportPopupButton button {
  width: fit-content;
  margin: 1rem;
  padding: 1rem 3rem;
  background-color: transparent;
  border: 1.75px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  border-radius: 30px;
}
.popupInput textarea {
  width: 95%;
  padding: 2rem 2rem;
  border-radius: 30px;
  color: #1b8fe7;

  outline: none;
  margin: 1rem;
}
.popupText form {
  display: flex;
  flex-direction: column;
}
.popupText form input,
.popupText form textarea {
  border: none;
  outline: none;
  border-radius: 40px;
  margin: 5px 0px;
  padding: 10px 20px;
  font-family: myraidproregular;
  color: #1b8fe7;
  font-size: 2rem;
}
.popupText form textarea {
  border-radius: 25px;
  font-family: myraidproregular;
  color: #1b8fe7;
  resize: none;
}
.popupText form input::-webkit-input-placeholder,
.popupText form textarea::-webkit-input-placeholder {
  color: #1b8fe7;
  opacity: 1;
}
/* 
@media(max-width:1065px){
    .ContactSection{
        width: 100%;
       margin-top: 5rem;
    }
    .ContactSection p{
        font-size: 30rem;
        letter-spacing: 2px;
       
    }
    .ContactSection .StrokeContact{
        font-size: 12rem;
       
    }
    .Home{
        display: none;
    }
    .MHome{
        display: flex;
        flex-direction: column;
    }
    .Mtransition,.MAbout,.MWatch,.MSupport{
        height: 100vh;
        width: 100%;
        position: relative;
    }
    .MTransitionBg{
        width: 100%;
        height: 100%;
    }
    .MTransitionBg img{
        width: 100%;
        height: auto;
    }
    .MTransitionContent{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .MPlayBtn{
        width: 80%;
    }
    .MPlayBtn img{
        width: 100%;
    }
    .MAppStore{
        width: 80%;
    }
    .MAppStore img{
        width: 100%;
    }
    .MSupportBg {
        width: 100%;
    }
    .MWatch{
        width: 100%;
        height: 100vh;
    }
    .MWatch img{
        width: 100%;
        height: 100%;
        object-fit: cover;

    }
    .MSupportBg img{
        width: 100%;
    }
    .popupText form input,.popupText form textarea{
        margin: 5px 0px;
        padding:10px 20px;
        font-size: 3rem;
    }
    .SupportContent{
        align-items: start;
        line-height: 13rem;
    }
    .Contact{
        margin-top: 25%;
    }
}
@media(max-width:768px){
    .ContactSection p{
        font-size: 25rem;
    }
    .ContactSection .StrokeContact{
        font-size: 11rem;
    }
}
@media(max-width:767px){
    .ContactSection p{
    font-family: drumshake_italic;
    font-size: 20rem;
    margin: 0;
    line-height: 50px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 5rem;
    }
    .ContactSection .StrokeContact{
        font-size: 9rem;
    }

    .Ticket a,.TicketTop a{
        position: -webkit-fixed;
        position: fixed;
        z-index: 999;
        top: 8%;
        right: 3%;
        min-width: 10rem;
        max-width: 22rem;
        height: 64px;
        background-color: red;
        border: 7px solid #949393;
        border-radius: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly; 
        align-items: center;
        transition: all 0.25s ease-in-out;
    }
    .TicketTop a{
        top: 2%;
    }
    .MPlayBtn{
        width: 80%;
    }
    .MAppStore{
        width: 80%;  
    }
    /* .popup{ 
        padding:2rem;
        width: 50rem;
        
        } */
.popupText form input,
.popupText form textarea {
  margin: 6px 0px;
  padding: 10px 20px;
  font-size: 2rem;
}
@media (max-width: 1065px) {
  .popupText form {
    width: 100%;
  }
  .popup {
    padding: 1rem 2rem;
  }
  .ReportPopupButton button {
    margin: 1rem 0;
  }
}
@media (max-width: 599px) {
  .MAppStore {
    width: 100%;
  }
  .ContactSection {
    margin-top: 2rem;
  }
  .SupportContent {
    /* line-height: normal; */
    align-items: center;
  }
  .ContactSection p {
    font-size: 11rem;
    letter-spacing: 4px;
  }
  .ContactSection .StrokeContact {
    font-size: 4.75rem;
    font-weight: 600;
    margin-top: 2rem;
    letter-spacing: 1px;
  }
  .MSupport {
    background-position: 10%;
    background-size: cover;
    /* margin-top: -7%; */
  }
  .MWatch {
    margin-top: -1%;
  }
  .popup {
    padding: 2rem;
    width: 34rem;
    height: 52rem;
  }
  .popupText {
    width: 100%;
  }
  .popupText form {
    width: 100%;
  }
  .popupText form input,
  .popupText form textarea {
    margin: 5px 0px;
    padding: 10px 10px;
    font-size: 1.5rem;
  }
  .ReportPopupButton button {
    margin: 1.5rem 0;
    padding: 1rem 3rem;
    font-size: 1.5rem;
  }
  .Contact {
    margin-top: 27rem;
    width: 100%;
  }
  .appStore {
    width: 120px;
  }
  .playStore {
    width: 130px;
  }
}
@media (max-width: 280px) {
  .ContactSection p {
    font-size: 9rem;
    margin-bottom: 1rem;
  }
  .ContactSection .StrokeContact {
    font-size: 3.75rem;
  }
}
.Crane img,
.MCrane img {
  width: 51%;
}
.MCrane img {
  width: 100%;
}
.Contact img {
  cursor: pointer;
}
.HideVideo {
  display: none;
}
.AppStore,
.MAppStore img {
  /* cursor: pointer; */
}
.playStore,
.PlayStore {
  /* cursor: pointer; */
}
.popupSection {
  width: 100%;
  z-index: 99;
}
@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .MPlayBtn {
    width: 40%;
  }
  .MCrane {
    width: 60%;
    left: 20%;
  }
  .MTransitionContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 12%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
  }
  .Contact {
    width: 60%;
    left: 50%;
    transform: translate(35%, 0%);
    margin-top: 10rem;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .popup {
    width: 45rem;
  }
}

@media (max-width: 390px) {
  .MWatch {
    display: flex;
    height: auto;
  }
  /* .MWatchLandscape{
        display: none;
    } */
}
.MWatchLandscape {
  display: none;
}
@media (min-width: 390px) {
  .MWatch {
    /* display: none; */
    height: auto;
  }
  /* .MWatchLandscape{
        display: flex;
    } */
}
.MPlayBtn,
.MPlayBtn img {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.warehousePopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999;
  top: 50%;
  left: 50%;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 20px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
  height: 22rem;
}
.warehousePopupText {
  margin-top: 10%;
}
.warehousePopupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.25rem;
  font-weight: 100;
}
.popupOverlaySection {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 9999;
}
.ResendpopupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  /* width: 50%; */
}
.ResendpopupImage img {
  width: 100%;
}
