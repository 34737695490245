.Container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Loader{
    width: 150px;
    height: 150px;
    border-bottom: 2px solid #000;
    border-radius: 50%;
    animation: loader 500ms linear infinite;
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
@keyframes loader{
    to {
        transform: rotateZ(-360deg);
      }
}