.Container {
  width: 100%;
  height: 100vh;
  background: #cecece;
  display: flex;
  align-items: center;
}
.popup {
  /* background-image: url("../../assests/Stripe\ Payment\ Confirmed\ Screen.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  display: flex;
  align-items: center;
  justify-content: center;
 
  position: relative;
}
.imageSection{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageSection img{
  width: 100%;
  height: 100%;
}
.confirm {
  position: absolute;
  top: 62%;
  display: flex;
  align-items: center;
}
.confirm button {
  color: #6667d1;
  font-size: 2rem;
}
.M_All {
  display: none;
}

@media (max-width: 1065px) {

    /* .popup {
        display: none;
      }
      .M_All {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      .Mpopup {
        display: flex;
        background-image: url("../../assests/Stripe\ Payment\ Confirmed\ Screen.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        align-items: center;
        width: 100%;
        height: 34rem;
        justify-content: center;
        position: relative;
      }
      .Mconfirm {
        position: absolute;
        top: 90%;
        display: flex;
        align-items: center;
      }
      .Mconfirm button {
        color: #6667d1;
        font-size: 2.5rem;
      } */

      .imageSection{
        height: 65vh;
      }
      .imageSection img{
        object-fit: cover;
      }

}
@media (max-width: 767px) {


  .imageSection{
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imageSection img{
    object-fit: cover;
  }
  /* .popup {
    display: none;
  }
  .M_All {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .Mpopup {
    display: flex;
    background-image: url("../../assests/Stripe\ Payment\ Confirmed\ Screen.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    width: 100%;
    height: 17rem;
    justify-content: center;
    position: relative;
  }
  .Mconfirm {
    position: absolute;
    top: 80%;
    display: flex;
    align-items: center;
  }
  .Mconfirm button {
    color: #6667d1;
    font-size: 1rem;
  } */
}