.Container {
  width: 100%;
  /* height: 100vh; */
  background-image: url("../../assests/Shipping Page BG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.Cart {
  width: 68rem;
  overflow-y: scroll;
  background-color: #2c2d2c;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  padding: 50px 25px;
}
.Cart::-webkit-scrollbar {
  display: none;
}
.Title {
  width: 30%;
  margin: 0 auto;
  border-radius: 30px;
  border: 1px solid #fff;
}
.Title p {
  font-family: drumshake_italic;
  font-size: 6rem;
  color: #fff;
  text-align: center;
}
.Carts {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0px;
  padding: 40px;
  padding-top: 0;
  height: 50vh;
  overflow: scroll;
  border-bottom: 1.5px solid #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}
.Carts::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  /* background-color:transparent; */
  width: 10px;
}
.Carts::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}
.Carts::-webkit-scrollbar-thumb {
  background: #1b8fe7;
  border-radius: 10px;
}
.CartItem {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Game {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Actions {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.replay img,
.share img {
  width: 4rem;
}
.image {
  width: 45%;
}
.name {
  width: 75%;
  margin-left: 3rem;
}
.image img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.name p {
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
}
.Shipping {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-bottom: 1.5px solid #fff;
  padding-top: 0;
}
.PrimeShipping {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.shippingIcon {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.shippingIcon img {
  width: 100%;
}
.selection {
  width: 22%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.selection .price p {
  color: #fff;
  font-weight: 500;
  font-size: 2.5rem;
  /* margin-right: 0.5rem; */
}
.Circle,
.CircleActive {
  margin-left: 1.75rem;
  width: 4rem;
  height: 4rem;
  border: 0.3rem solid #fff;
  border-radius: 50%;
  background-color: lightgray;
}
.Shipping .CircleActive {
  margin-left: 1.5rem;
}
.CircleActive {
  background-color: #75f85f;
}
.FreeShipping .selection {
  margin-left: 0;
}
.FreeShipping {
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.shippingIcon p {
  font-size: 2.25rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.Price p {
  text-align: center;
  font-size: 2.5rem;
  color: #fff;
  font-weight: 500;
}
.FreeShipping .selection {
  width: 32.5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.ticket {
  width: 5rem;
  /* height: 30rem; */
}
.ticket img {
  width: 100%;
}
/* .FreeShipping .selection .Circle {
  margin-left: 0;
} */
.Checkout {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Checkout button {
  width: 40%;
  margin: 0 auto;
  border-radius: 5rem;
  border: 1px solid #fff;
  background-color: #3b82f6;
  font-family: drumshake_italic;
  font-size: 6rem;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
}
.bundleOverlay {
  width: 100vw;
  height: 100%;
  background-color: #0000008a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.bundlePopup {
  position: absolute;
  width: 55rem;
  z-index: 999999999999;
  left: 50%;
  transform: translate(-50%, -45%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 50%;
}
.bundleCloseContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.bundleClose {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bundleClose img {
  width: 100%;
  height: 100%;
}
.bundleSection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bundleSection img {
  width: 100%;
  height: 100%;
}
.price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.priceImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  margin: 0 0.5rem;
}
.priceImage img {
  width: 100%;
  height: 100%;
}
.tokenOverlay {
  width: 100vw;
  height: 100%;
  background-color: #0000008a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.tokenPopup {
  position: absolute;
  width: 50rem;
  z-index: 999999999999;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 50%;
  height: 50rem;
}
.tokenCloseContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;
}
.tokenClose {
  width: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tokenClose img {
  width: 100%;
  height: 100%;
}
.tokenSection {
  background-image: url("../../assests/Full\ Box\ BG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 13px;
  border: 2px solid #fff;
  width: 85%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.tokenHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 35%;
}
.tokenWincha {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-20%, -30%);
}
.tokenWincha img {
  width: 100%;
  height: 100%;
}
.tokenValue {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 80%;
}
.tokenTxt1,
.tokenTxt2,
.tokenTxt3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 14%;
}
.tokenTxt1 p,
.tokenTxt2 p,
.tokenTxt3 p {
  font-size: 8.5rem;
  font-family: drumshake_italic;
  position: absolute;
}

.tokenTxt1 p:nth-child(1),
.tokenTxt2 p:nth-child(1),
.tokenTxt3 p:nth-child(1) {
  color: white;
  -webkit-text-stroke: 8px white;
}
.tokenTxt1 p:nth-child(2) {
  color: #f6b700;
}
.tokenTxt2 p:nth-child(2),
.tokenTxt3 p:nth-child(2) {
  color: #000000;
}
.tokenNoValue {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 80%;
}
.tokenNoValue p {
  font-size: 5.5rem;
  font-family: drumshake_italic;
  position: absolute;
}
.tokenNoValue p:nth-child(1) {
  color: white;
  -webkit-text-stroke: 8px white;
}
.tokenNoValue p:nth-child(2) {
  color: #000000;
}
.tokenText,
.tokenNoText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 20%;
}
.tokenText p {
  font-size: 5.5rem;
  font-family: drumshake_italic;
  position: absolute;
}
.tokenText p:nth-child(1) {
  color: white;
  -webkit-text-stroke: 8px white;
}
.tokenText p:nth-child(2) {
  color: #000000;
}
.tokenNoText p {
  font-size: 4.5rem;
  font-family: drumshake_italic;
  position: absolute;
}
.tokenNoText p:nth-child(1) {
  color: white;
  -webkit-text-stroke: 8px white;
}
.tokenNoText p:nth-child(2) {
  color: #000000;
}
.tokenDescription,
.tokenNoDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 45%;
}
.tokenDescription p,
.tokenNoDescription p {
  font-size: 5.5rem;
  font-family: drumshake_italic;
  line-height: 40px;
  letter-spacing: -1px;
  text-align: center;
  position: absolute;
}
.tokenDescription p:nth-child(1),
.tokenNoDescription p:nth-child(1) {
  color: white;
  -webkit-text-stroke: 8px white;
}
.tokenDescription p:nth-child(2),
.tokenNoDescription p:nth-child(2) {
  color: #000000;
}

.tokenImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}
.tokenButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  cursor: pointer;
}
.tokenButton img {
  width: 100%;
  height: 100%;
}
@media (max-width: 599px) {
  .Cart {
    width: 100%;
    padding: 10px;
  }
  .Carts {
    padding: 10px;
    height: auto;
    min-height: 35vh;
    max-height: 50vh;
    overflow-y: scroll;
  }
}
@media (max-width: 765px) {
  .Title p {
    font-size: 3.5rem;
  }
  .Checkout button {
    font-size: 3.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name p {
    font-size: 1.5rem;
  }
  .replay img,
  .share img {
    width: 2.75rem;
  }
  .shippingIcon {
    width: 50%;
  }
  .selection .price p {
    font-size: 2.5rem;
  }
  .price p {
    margin-right: 0.5rem;
  }

  .shippingIcon p {
    font-size: 2.5rem;
  }
  .selection {
    width: 30%;
  }
  .FreeShipping .selection {
    width: 37%;
  }
  .ticket {
    width: 5rem;
  }
  .CircleActive,
  .Circle {
    width: 4rem;
    height: 4rem;
  }
}
.Popup {
  position: absolute;
  top: 0%;
  border-radius: 40px;
  width: 20%;
  background-color: #000;
  /* height: 100%; */
  outline: 10px solid #949393;
  border: 7.5px solid #3b82f6;
  overflow: hidden;
  z-index: 99;
  left: 50%;
  transform: translate(-50%, 50%);
}
.playerButton {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}
.playerButton img {
  width: 20%;
}
video {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* border-radius: 40px; */
}
.popup {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b8fe7;
  border: 1rem solid #949393;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  /* width: 15%; */
}
.popupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}
.popupImage img {
  width: 100%;
  object-fit: contain;
}
.popupText {
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.popupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  width: 300px;
  font-weight: 500;
  /* word-wrap: break-word; */
  line-height: 25px;
}
.phnpopupText p {
  font-size: 2rem;
  font-size: 2rem;
  text-align: center;
  align-items: center;
  color: #fff;
  width: 300px;
  font-weight: 500;
  /* word-wrap: break-word; */
  line-height: 25px;
  margin-top: 5rem;
}
.DetailspopupText {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.DetailspopupText p {
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
  width: 300px;
  font-weight: 500;
  line-height: 25px;
}
.popupButton button {
  margin: 1.5rem;
  padding: 1rem 7.5rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
}
.ReportPopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.ReportPopupButton button {
  margin: 1.5rem;
  padding: 1rem 7.5rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
}
.popupInput textarea {
  width: 95%;
  padding: 2rem 2rem;
  border-radius: 30px;
  color: #1b8fe7;

  outline: none;
  margin: 1rem;
}
.postpopup {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b8fe7;
  border: 1rem solid #949393;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33rem;
  height: 20rem;
}
.popupbutton button {
  /* margin: 2.5rem; */
  padding: 0.5rem 4.5rem;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 600;
  border-radius: 30px;
  margin-top: 3rem;
}
.Address {
  width: 68rem;
  position: absolute;
  top: 7%;
  /* height: 70%; */
  left: 50%;
  height: 100vh;
  z-index: 9;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* /* overflow-y: scroll !important; */
}
.Address form {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  width: 75%;
  display: flex;
  position: relative;
  z-index: 99;
  flex-direction: column;
}
.Address form input {
  font-family: "Poppins";
  width: 100%;
  font-size: 3rem;
  background-color: #fff;
  height: 7.5rem;
  /* height: 5.68rem; */
  padding: 1.13rem 2.26rem;
  text-align: left;
  border-radius: 1.13rem;
  margin: 1.13rem 0;
  border: 0.25rem solid #333333c1;
  /* outline:.25rem solid #000; */
  text-align: center;
}
.Address form input:focus {
  outline: 0.25rem solid #1b8fe7;
  border-color: transparent;
}
.Address form h1 {
  text-align: left;
  font-weight: 500;
  color: #333333c1;
  text-align: left;
  margin-right: auto;
}
.Address form {
  align-items: center;
}
.Address form button {
  width: 75%;
  border-radius: 50px;
  background-color: #3b82f6;
  border: none;
  outline: none;
  height: 7.5rem;
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  margin-top: 2rem;
}
.Downarrow {
  font-size: 3rem;
}
.StateSelect {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  height: 100%;
  font-size: 3.5rem;
}
.selectInput {
  position: relative;
  width: 100%;
}
.AllState {
  position: absolute;
  width: 100%;
  left: 0;
  top: 95%;
  height: 450px;
  overflow-y: scroll;
  background-color: #010020;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 15px;
  z-index: 9;
}
.stateUp {
  animation: slideUp 500ms ease;
}
.AllState::-webkit-scrollbar {
  display: none;
}
.AllState input {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  border-bottom: 0.5px solid #fff;
  padding: 10px;
  outline: none;
  color: #000;
}
.LoaderDiv {
  /* position: absolute; */
  /* top: 50%; */
  width: 100%;
  height: 100%;
  /* left: 50%; */
  /* transform: translate(-50%,-50%); */
  /* width: 20rem; */
  /* background-color: #000000c9; */
}
.LoaderAnime {
  width: 13rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.CloseIcon {
  z-index: 9;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  border-radius: 50%;
  background-color: #e50000;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.CloseIcon svg {
  color: #fff;
  font-size: 3rem;
}

.LastWinPopup {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 50%;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.VideoSection {
  width: 400px;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
  border: 10px solid #949393;

  /* border: 10px solid #1b8fe7; */
  /* outline: 10px solid #949393; */
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PlayIcon button img {
  width: 20%;
}

.PlayImage {
  margin: 0 auto;
  width: 80%;
  position: relative;
}

.PlayImage button {
  position: relative;
  z-index: 99;
}
.VideoSection div {
  background-color: #1b8fe7;
  width: 100%;
  height: 100% !important;
}

.VideoSection video {
  background-color: #000;
  border: 10px solid #1b8fe7;
  border-radius: 30px;
  /* width: 100%; */
  /* height: 100%; */
  /* border-radius: 30px; */
  /* border: 14px solid #1b8fe7; */
  /* outline: 14x solid #1b8fe7; */
}
.hideVideopopup {
  display: none;
}
.VideoSection .VideoEmpty {
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoEmpty p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
/* .VideoOverlay{
    width: 100vw;
    height: 100vh;
    background-color: #00000028;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
} */
.VideoSection svg {
  position: absolute;
  right: 5%;
  top: 5%;
  font-size: 5rem;
  z-index: 9999;
  color: #fff;
}
.VideoEmptyOverlay h1 {
}
.videoStarting {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(27, 143, 231, 1) 0%,
    rgba(19, 192, 236, 1) 50%,
    rgba(32, 122, 224, 1) 100%
  );
  z-index: 999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 8rem;
  font-weight: 600;
  font-family: drumshake_italic;
}
.videoStarting p {
  text-align: center;
}
.VideoOverlay {
  width: 100vw;
  height: 100%;
  background-color: #00000028;
  position: fixed;
  top: 0;
  left: 0;
}
.share {
  position: relative;
}
.ShareDiv {
  position: absolute;
  display: flex;
  z-index: 9999;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1b8fe7;
  border-radius: 25px;
  padding: 5px 10px;
  left: 0;
  top: 0;
  transform: translate(-75%, -125%);
}
.ShareIcon {
  margin: 0px 7px;
  color: #fff;
  font-size: 2.5rem;
  cursor: pointer;
  width: 2.25rem;
  display: flex;
  align-items: center;
}
.ShareIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1065px) {
  .Address form {
    width: 100%;
  }
  .Address {
    width: 50%;
    top: 4%;
  }
  .Address form input {
    font-family: "Poppins";
    width: 100%;
    font-size: 2rem;
    background-color: #fff;
    height: 5.5rem;
    /* height: 5.68rem; */
    padding: 1.13rem 1.26rem;
    text-align: center;
    border-radius: 1.13rem;
    margin: 1.13rem 0;
    border: 0.15rem solid #333333c1;
  }
  .VideoSection {
    width: 500px;
    height: 400px;
    border-width: 5px;
    outline-width: 5px;
    border-radius: 35px;
  }
  .VideoSection video {
    border-width: 5px;
    border-radius: 30px;
  }
  .ShareDiv {
    transform: translate(-90%, -125%);
  }
  .Address form h1 {
    font-size: 1.5rem;
  }
  .Address form button {
    width: 60%;
    border-radius: 50px;
    background-color: #3b82f6;
    border: none;
    outline: none;
    height: 5.5rem;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    margin-top: 2rem;
  }
  .Passpopup {
    width: 90%;
  }
  .selectInput {
    width: 100%;
  }
  .selectInput .AllState input {
    font-size: 2.5rem;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .bundlePopup {
    width: 80rem;
    transform: translate(-50%, -55%);
  }
  .bundleClose {
    width: 8%;
  }
  .tokenPopup {
    height: 60rem;
    width: 60rem;
  }
  .tokenClose {
    width: 8%;
  }
  .tokenClose img {
    margin-right: 5rem;
  }
  .tokenValue p,
  .tokenNoValue p {
    font-size: 9rem;
  }
  .tokenText p,
  .tokenDescription p {
    font-size: 6.5rem;
  }
  .tokenNoText p,
  .tokenNoDescription p {
    font-size: 6rem;
  }
  .tokenWincha {
    width: 35%;
  }
  .tokenButton {
    width: 55%;
  }
}
.DeliveryAddress {
  width: 90%;
  padding: 25px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-evenly;
}
.AddressSection {
  width: 30%;
}
.AddressSection input {
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 2.25rem;
  margin: 5px 0;
  font-weight: 600;
  width: 100%;
}
.AddressTitle p {
  font-size: 2.25rem;
  font-weight: 600;
  color: #fff;
}
.AddressEditBtn button {
  background-color: #1b8fe7;
  border: 1.5px solid #fff;
  outline: none;
  font-size: 2rem;
  color: #fff;
  padding: 5px 15px;
  border-radius: 30px;
  margin: 20px 0;
  font-weight: 700;
}
.AddressHeadSeciton {
  width: 50%;
}
.AddressHeadSection button {
  margin: 10px 0;
}
.Blank {
  color: transparent;
  opacity: 0;
}
@media (max-width: 767px) {
  .AddressSection input {
    font-size: 1.75rem;
  }
  .AddressTitle p {
    font-size: 1.75rem;
  }
  .DeliveryAddress {
    padding: 25px 10px;
    width: 100%;
  }
  .AddressEditBtn button {
    background-color: #1b8fe7;
    border: 1.5px solid #fff;
    outline: none;
    font-size: 1.5rem;
    color: #fff;
    padding: 3px 10px;
    border-radius: 30px;
    margin: 20px 0;
    font-weight: 700;
  }
  /* .Circle{
        margin-left: 0;
    }
    .price p{
        margin-right: 15px;
    } */
}
@media (max-width: 599px) {
  .VideoSection {
    width: 90%;
    height: 250px;
  }
  .selection .price p {
    font-size: 1.5rem;
  }
  .priceImage {
    width: 17%;
    margin: 0 0.75rem;
  }
  .price p {
    margin-right: 0;
  }
  .shippingIcon p {
    font-size: 1.5rem;
  }
  .selection {
    width: 30%;
  }
  .FreeShipping .selection {
    width: 37%;
  }
  .ticket {
    width: 3rem;
  }
  .CircleActive,
  .Circle {
    width: 3rem;
    height: 3rem;
  }
  .Shipping {
    padding: 20px;
  }
  .popup {
    width: 35rem;
  }
  .ReportPopupButton button {
    margin: 1.5rem;
    padding: 1rem 5rem;
    background-color: transparent;
    border: 3px solid #fff;
    outline: none;
    color: #fff;
    font-size: 1.75rem;
    font-weight: 600;
    border-radius: 30px;
  }
  .DetailspopupText {
    margin-top: 10%;
    padding: 1rem;
  }
  .DetailspopupText p {
    font-size: 2.25rem;
    width: 250px;
    font-weight: 500;
    line-height: 25px;
  }
  .bundlePopup {
    width: 40rem;
    transform: translate(-50%, -50%);
  }
  .bundleClose {
    width: 8%;
    margin-bottom: 2rem;
    margin-right: 0.75rem;
  }
  .tokenPopup {
    height: 55rem;
    width: 46rem;
  }
  .tokenClose {
    width: 8%;
  }
  .tokenClose img {
    margin-right: 5rem;
  }
  .tokenWincha {
    width: 30%;
  }
  .tokenButton {
    width: 50%;
  }
}
.clubHousePopup {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 9999;
}
.ClubHouse {
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  background: #fff;
  border: 3px solid #fff;
  height: auto;
  border-radius: 17px;
  /* height: 80vh; */
}
.TopImage {
  width: 100%;
  position: relative;
  height: 50vh;
}
.TopImage img {
  width: 100%;
}
.topImageContainer {
  position: absolute;
  width: calc(100% + 1vw); 
  z-index: 999;
  transform: translateX(-3.75%);
  height: 100%;
}
.topImageContainer img {
  width: 100%; 
  height: 100%;
  border-radius: 0px 15px 0 0;
  
}
.exclusiveImage{
  position: absolute;
  z-index: 9999;
  bottom: 0;
  transform: translateY(40%);
  width: calc(100% + 20px);
  left: -10px;
}
.exclusiveImage img{
  width: 100%;
}
.clubHouseClose {
  position: absolute;
  top: 2%;
  right: 2%;
  width: 7.5%;
  cursor: pointer;
  z-index: 9999;
}
.clubHouseClose img {
  width: 100%;
}
.BottomContents {
  width: 100%;
  position: relative;
  padding-bottom: 5px;
  min-height: 180px;
  background-image: url("../../assests/Artboard\ 48\ -\ W\ Background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0 0 17px 17px;
}
.Overlay {
  background-color: transparent;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  top: 0;
}
.LowerImg {
  z-index: 9;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.LowerImg img {
  width: 100%;
}
.BonusPoints {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 99;
  align-items: center;
  justify-content: center;
}
.Bonus p {
  font-size: 8rem;
  /* font-family:bubbleGum; */
  font-family: drumshake_italic;
  color: #fff;
  -webkit-text-stroke: 2px #000;
}
.BonusText {
  margin: 0px 10px;
}
.BonusText p {
  font-style: italic;
  color: #000;
  /* font-family: myraidproregular; */
  font-size: 2.5rem;
  font-weight: 800;
}
.benefits {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 99;
  padding-top: 1rem;
}
.benefit {
  width: 100%;
}
.benefitImage {
  width: 90%;
}
.benefitImage img {
  width: 100%;
}
.SubscribeButton {
  /* width: 100%; */
  margin: 16px auto;
  position: relative;
  z-index: 99;
  background-color: #2373f3;
  border-radius: 30px;
  border: 3px solid #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  /* margin-bottom: 20px; */
  text-transform: uppercase;
}
.SubscribeButton button {
  width: fit-content;
  font-size: 5rem;
  color: #fff;
  font-family: drumshake_italic;
}
.CancelSubscription {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}
.CancelSubscription p {
  text-align: center;
  color: #1b8fe7;
  font-weight: 700;
  font-size: 1.5rem;
  margin: 10px 0px;
}
.TermsAndPolicy {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 10px;
}
.Terms p,
.Policy p {
  font-size: 2rem;
  color: #1b8fe7;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}
.replay img,
.share img {
  cursor: pointer;
}
.Circle,
.CircleActive {
  cursor: pointer;
}
.AddressEditBtn {
  cursor: pointer;
}
.AddressSection input::-webkit-input-placeholder {
  opacity: 1;
  color: gray;
}
.AddressTitleOverlay {
  position: absolute;
  width: 100vw;
  height: 200vh;
  background-color: transparent;
  z-index: 1;
}
.ShareOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.clubHouseOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9;
}
.CartEmptyText {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  /* text-transform: capitalize; */
  /* margin-top: 20%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 60%;
}
/* .Bonus{
    width: 30%;
} */
.BonusText {
  /* margin: 0px 20px; */
}
@media (max-width: 765px) {
  .CartEmptyText {
    font-size: 1.75rem;
  }
  .ClubHouse {
    width: 60%;
    border-radius: 20px;
    position: fixed;
  }
  .Bonus p {
    font-size: 6rem;
    text-align: end;
  }
  .BonusText p {
    font-size: 2.5rem;
  }
  .SubscribeButton button {
    font-size: 2.5rem;
  }
  .CancelSubscription p,
  .TermsAndPolicy p {
    font-size: 1rem;
  }
  .topImageContainer {
    width: calc(100% + 2.1vw); 
    transform: translateX(-3.5%);
  }
}
@media (max-width: 712px) {
  .BonusText p {
    font-size: 2.5rem;
  }
}
@media (max-width: 599px) {
  .CartEmptyText {
    width: 70%;
    font-size: 1.5rem;
  }
  .Bonus p {
    font-size: 4rem;
    -webkit-text-stroke: 1.5px #000;
  }
  .BonusText {
    margin: 0px 7px;
  }
  .BonusText p {
    font-size: 1.5rem;
  }
  .Address {
    width: 80%;
    top: 1%;
  }
  .Address form input {
    font-size: 1.5rem;
    height: 4rem;
  }
  .LoaderAnime {
    width: 8rem;
  }
  .Downarrow {
    font-size: 2rem;
  }
  .selectInput .AllState input {
    font-size: 1.75rem;
  }
  .AllState {
    height: 350px;
  }
  .TopImage {
    height: 35vh;
  }
  .CancelSubscription img{
    width: 50%;
  }
}
@media screen and (max-width:490px) {
  .topImageContainer {
    width: calc(100% + 2.1vw); 
    transform: translateX(-3.5%);
  }
  
  .topImageContainer img {
    width: 100%; 
    height: 100%;
    border-radius:0px 15px 0 0;
    
  }
  .TopImage {
    height: 32vh;
  }
}
@media screen and (max-width:431px) {
  .TopImage {
    height: 30vh;
  }
}


@media (max-width: 280px) {
  .ClubHouse {
    width: 70%;
  }
  .Bonus p {
    font-size: 4rem;
  }
}
