.permissionContainer {
  position: fixed;
  top: 0;
  right: 50%;
  transform: translate(50%, 10%);
  padding: 1rem;
  background-color: #fff;
  border: 2px solid #5070b9;
  z-index: 9999;
  width: 30rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}
.permissionContainer p {
  font-family: myraidproregular;
  font-size: 1.75rem;
  color: #717171;
  font-weight: 600;
  margin-top: 1rem;
}
.permissionContainer button {
  width: 8rem;
  background: #6868c2;
  height: 3rem;
  text-align: center;
  color: #fff;
  border-radius: 6px;
  font-family: myraidproregular;
}
.overlay {
  width: 100vw;
  height: 100%;
  background-color: #0000008a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
