.Container {
  width: 100%;
  border-bottom: 5px solid #949393;
  /* position: fixed;
    z-index: 99999; */
  position: relative;
  min-height: 100%;
}
.Section {
  max-width: 1600px;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: #00000074; */
}
.Header {
  width: 100%;
  padding: 5px 25px;
  background-color: #2d2d2d;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* position: relative; */
}
.Logo {
  width: 5%;
  position: relative;
  /* transform: translateY(50%); */
  /* background-color: red; */
}
.Logo img {
  width: 8.5rem;
  z-index: 9999999;
  top: 0%;
  position: absolute;
  height: 8.5rem;
}
.Menu {
  width: 50%;
  /* background-color: yellow; */
}
.Menu ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  /* padding: 20px 25px; */
  justify-content: space-evenly;
  align-items: center;
}
.MenuSection .NormalUrl {
  /* font-size: 2.25rem; */
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  /* margin: 15px; */
  margin-right: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.MenuSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: flex-start; */
  /* height: 100%; */
  margin: 15px 0;
}
.MenuSection .ActiveUrl {
  color: #1b8fe7;
  /* font-size: 2.25rem; */
  font-size: 18px;
  /* color: #fff; */
  font-weight: 600;
  cursor: pointer;

  /* margin: 15px; */
}
.MenuSection .NormalUrl:hover {
  color: #1b8fe7;
  font-size: 18px;
  /* color: #fff; */
  font-weight: 600;
  cursor: pointer;

  /* margin: 15px; */
  /* margin-top: 5px;
transform: scale(1.1); */
}
.Settings ul button li {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.Credits {
  width: 10%;
  background-color: #09061d;
  border-radius: 50px;
  position: relative;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Ticket {
  width: 5rem;
}
.Ticket img {
  width: 100%;
}
.Plus {
  width: 3.5rem;
}
.Plus img {
  width: 100%;
}
.Points p {
  font-size: 2.5rem;
  font-family: bubblegum;
  color: #000;
  -webkit-text-stroke: 1px #fff;
}
.Profile {
  /* width: 15%; */
  min-width: 12%;
  width: fit-content;
  /* z-index: 999; */
  /* background-color: blue; */
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Username,
.LoginRegister {
  font-size: 2.25rem;
  color: #fff;
  font-weight: 600;
  margin: 15px;
}
.LoginRegister {
  cursor: pointer;
}
.Profile svg {
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
}
.Settings {
  padding: 20px 25px;
  width: 40rem;
  position: absolute;
  /* top: 6rem; */
  right: 0;
  top: 100%;
  transform: translateY(1%);
  z-index: 99999;
  background-color: #2c2d2c;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.Settings ul {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  padding: 0rem 0;
}

.MenuSection {
  padding: 0px 0;
}
.Settings ul li {
  color: #fff;
  list-style: none;
  font-weight: 600;
  /* font-size: 2.25rem; */
  font-size: 18px;
  /* margin: 15px; */
  transition: all 0.2s ease-in-out;
}
.Settings ul li:hover {
  color: #1b8fe7;
  /* margin-top: 5px;
    transform: scale(1.1); */
}
@media (max-width: 1056px) {
  .MobileMenu {
    /* height: 10vh; */
    background-color: #2d2d2d;
    width: 100%;
    padding: 0 2rem;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .MobileMenu .Menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* padding: 20px 25px; */
  }
  .menuIcon {
    color: #fff;
    font-size: 3.5rem;
  }
  .MobileMenu .Menu p {
    font-size: 2rem;
    font-weight: 900;
    color: #fff;
    margin-left: 1.5rem;
    font-style: italic;
  }
  .Credits {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
  }
}
.MobileMenu {
  display: none;
}
.mobileFullMenu {
  display: none;
}

.PopupContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
  z-index: 999999;
}
.popup {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b8fe7;
  border: 1rem solid #949393;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  /* width: 15%; */
}
.popupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}
.popupImage img {
  width: 100%;
  object-fit: contain;
}
.popupText {
  margin-top: 15%;
}
.popupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  /* width: 300px; */
  font-weight: 500;
  /* word-wrap: break-word; */
  line-height: 25px;
}
.popupButton button {
  margin: 1.5rem;
  /* padding: 1rem 7.5rem; */
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  width: 50%;
  font-weight: 600;
  border-radius: 30px;
}
.ReportPopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.ReportPopupButton button {
  margin: 1.5rem;
  padding: 1rem 0rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  width: 50%;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
}
.popupInput textarea {
  width: 95%;
  padding: 2rem 2rem;
  border-radius: 30px;
  color: #1b8fe7;

  outline: none;
  margin: 1rem;
}
.popup {
  position: fixed;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-50%, -50%);
  background-color: #0a84fe;
  border: 1rem solid #949393;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40rem;
  font-family: myraidproregular;
}
.popupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}
.popupImage img {
  width: 100%;
  object-fit: contain;
}
.popupText {
  margin-top: 15%;
}
.popupText p {
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.popupButton {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.popupButton button {
  margin: 1.5rem;
  padding: 1rem 7.5rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
}
.ReportPopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ReportPopupButton button {
  margin: 1.5rem;
  padding: 1.25rem;
  width: 35%;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 100;
  border-radius: 30px;
}
.popupInput textarea {
  width: 95%;
  padding: 2rem 2rem;
  border-radius: 30px;
  color: #0a84fe;

  outline: none;
  margin: 1rem;
}
.popupSubmit button {
  margin: 1.5rem;
  width: 50%;
  padding: 1rem 7.5rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 30px;
}
.bandaiLogo {
  width: 10%;
  display: flex;
  align-items: center;
  cursor: alias;
}
.bandaiLogo img {
  width: 100%;
}
.MobileBottomNav {
  display: none;
}

/* @media(min-width:1065px){
    .Container{
        width: fit-content;
    }
} */
.CartBadge {
  background-color: #e60000;
  border-radius: 50%;
  font-size: 1.35rem;
  padding: 0;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6.85rem;
  color: #fff;
  position: absolute;
  margin-bottom: 1.95rem;
}
.NotificationCount {
  background-color: #e60000;
  border-radius: 50%;
  font-size: 11px;
  padding: 0;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  margin-bottom: 2rem;
  margin-left: 12.85rem;
}
.notificationBadge {
  background-color: #e60000;
  border-radius: 50%;
  font-size: 11px;
  padding: 0;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0rem 1rem; */
  color: #fff;
  position: absolute;
  margin-left: 2.75rem;
  top: 1.5rem;
}
.Music,
.Sound {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Slider {
  width: 30%;
  height: 3rem;
  border-radius: 5rem;
  background-color: #5b5b5b;
  cursor: pointer;
  position: relative;
}
.SliderBtn {
  background-color: #a8a8a8;
  width: 50%;
  border-radius: 5rem;
  height: 3rem;
  left: 0;
  cursor: pointer;
}
.ActiveSlider {
  background-color: #72f456;
  width: 30%;
  height: 3rem;
  border-radius: 5rem;
  position: relative;
  cursor: pointer;
}
.ActiveSliderBtn {
  background-color: #a8a8a8;
  width: 50%;
  right: 0;
  position: absolute;
  border-radius: 5rem;
  height: 3rem;
  cursor: pointer;
}
.MobileTopNav {
  display: none;
}
@media (max-width: 1065px) {
  .Container {
    border-width: 23x;
  }
  .MobileMenu {
    bottom: 0;
    position: fixed;
    left: 0;
  }
  .MobileTopNav {
    width: 100%;
    display: flex;
    height: 8rem;
    /* position: fixed; */
    /* top:0; */
    /* left:0; */
    padding: 0px;
    padding: 0px 10px;
    background-color: #333;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .MLogo {
    width: 6%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 7.5%;
    transform: translate(-25%, 60%);
    transform: translate(-25%, 60%);
    z-index: 10000000;
  }
  .MLogo img {
    width: 100%;
  }
  .MBandaiLogo {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5rem;
    margin-top: 1px;
  }
  .MBandaiLogo img {
    width: 100%;
  }
  .MCredits {
    width: 22%;
    height: 5rem;
    background-color: #09061d;
    border-radius: 50px;
    position: relative;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(0%, -50%);
  }
  .MTicket {
    width: 30%;
  }
  .MTicket img {
    width: 100%;
  }
  .MPlus {
    width: 23%;
  }
  .MPlus img {
    width: 100%;
  }
  .MPoints p {
    font-size: 3.5rem;
    font-family: bubblegum;
    color: #000;
    -webkit-text-stroke: 1px #fff;
  }
  .Menu {
    width: 100%;
  }
  .HomeMenuIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 100%;
    position: relative;
    background: #2c2d2c;
    z-index: 999999999;
  }
  .BottomMenuIcon {
    width: 11.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .BottomMenuIcon img {
    width: 100%;
  }
  .BottomMenuIcon:nth-child(3) {
    width: 11.5%;
    margin-top: 4px;
    position: relative;
  }
  .BottomMenuIcon:nth-child(3) img {
    position: absolute;
  }
  .HomeMenuIcons svg {
    /* width: 100%; */
    color: #fff;
    font-size: 9rem;
  }
  .MobileMenu {
    padding: 5px 7.5px;
    z-index: 9999999;
  }
  .MobileMenu .HamBurgerMenu .Settings {
    top: -1%;
    width: 80%;
    position: fixed;
    /* z-index: 99999999;
    position: absolute; */
    z-index: 99999999;
    height: fit-content;
    bottom: 0;
    top: -100%;
    border-radius: 0;
    transform: translateY(-85%);
    border-top-left-radius: 30px;
  }
  .MenuSection {
    margin: 15px 0px;
  }
  .Settings ul li {
    margin: 0;
  }
  .BottomMenuIcon:nth-child(4) {
    width: 16%;
  }
  .BottomMenuIcon:nth-child(4) img {
    width: 70%;
  }
  .BottomMenuIcon .CartBadge {
    width: 30px;
    height: 30px;
    font-size: 2rem;
    margin-left: 13rem;
    padding: auto;
    margin-bottom: 8rem;
  }
}
/* .MenuSection{
    display: flex;
    align-items: center;
} */
@media (max-width: 1065px) {
  .Container {
    position: sticky;
    top: 0;
    z-index: 99999999;
  }
  .MobileMenu {
    /* display: block; */
    display: flex;
  }
  .mobileFullMenu {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 9999;
    height: 100vh;
    background-color: #2d2d2d;
  }
  .mobileFullMenu .Menu {
    /* height: 60%; */
    /* padding: 5rem; */
  }
  .mobileFullMenu .Menu ul {
    color: #fff;
    display: flex;
    /* height: 100%; */
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 25px;
    /* justify-content: space-evenly; */
  }
  .Header {
    display: none;
  }
  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .closeIcon {
    font-size: 3.5rem;
    color: #fff;
  }
  .Menu .HamBurgerMenu svg {
    color: #fff;
    font-size: 3rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    color: #fff;
    font-size: 3rem;
    /* z-index: 999; */
  }
  .MobileMenu .HamBurgerMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .MobileMenu .HamBurgerMenu .Settings {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
    top: 100%;
    transform: translateY(-100%);
    padding: 5rem;
  }
  .Settings ul {
    padding: 15rem 0;
  }
  .MenuSection {
    padding: 5px 0;
    flex-direction: row-reverse;
  }
  .Music,
  .Sound {
    flex-direction: row-reverse;
  }
  .MenuSection .NormalUrl {
    font-size: 3rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 3rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 4rem;
  }
  .Settings ul li {
    font-size: 3rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 4rem;
    margin-top: 8%;
  }
  .Settings .closeIcon {
    position: absolute;
    right: 2rem;
  }
  .Credits {
    display: none;
  }
  .notificationBadge {
    width: 22px;
    height: 22px;
    font-size: 2rem;
    right: 1.5rem;
    margin-top: 1rem;
    z-index: 999999999;
  }
  .HomeMenuIcons svg {
    margin-right: 1.5rem;
  }
  .NotificationCount {
    width: 22px;
    height: 22px;
    font-size: 16px;
    margin-bottom: 2.75rem;
    right: 2.75rem;
  }
}
@media (max-width: 820px) {
  .MPoints p {
    font-size: 3rem;
  }
  .MCredits {
    width: 25%;
  }
  .notificationBadge {
    margin-top: 0;
  }
  .BottomMenuIcon .CartBadge {
    margin-bottom: 4rem;
    margin-left: 11rem;
  }
  .MBandaiLogo {
    padding: 4rem;
  }
}
@media (max-width: 768px) {
  .notificationBadge {
    font-size: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    right: 1.25rem;
    /* margin-top: 1rem; */
  }
  .BottomMenuIcon .CartBadge {
    margin-left: 10rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 5rem;
  }
  .MBandaiLogo {
    padding: 1rem;
  }
}
@media (max-width: 765px) {
  .MobileMenu .Menu {
    padding: 0;
  }
  .Menu ul {
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    padding: 20px 25px;
  }
  .MBandaiLogo {
    padding: 0;
  }
}
@media (max-width: 712px) {
  .MCredits {
    width: 26%;
    height: 5rem;
  }
  .MBandaiLogo {
    margin-top: 2px;
  }
}
@media (max-width: 555px) {
  .MobileTopNav {
    height: 4rem;
  }
  .MBandaiLogo {
    padding: 0;
  }
  .MCredits {
    width: 30%;
    height: 25px;
  }
  .MTicket {
    width: 30%;
  }
  .MPlus {
    width: 20%;
  }
  .MPoints p {
    font-size: 1.75rem;
    -webkit-text-stroke: 0.75px #fff;
  }
  .BottomMenuIcon .CartBadge {
    width: 13px;
    height: 13px;
    font-size: 1rem;
    padding: 2px;
    margin-left: 5.5rem;
    margin-bottom: 2.5rem;
  }
  .HomeMenuIcons svg {
    font-size: 4rem;
  }
  .MobileMenu {
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    background: #2c2d2c;
  }
}
@media (max-width: 554px) {
  .MobileTopNav {
    height: 5rem;
  }
  .MBandaiLogo {
    padding: 1.5rem;
  }
}
@media (max-width: 420px) {
  .MBandaiLogo {
    padding: 0rem;
  }
}
@media (max-width: 410px) {
  .MobileTopNav {
    height: 4rem;
  }
}
.SettingsOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: transparent;
}
/* .Settings{
    position: relative;
    z-index: 999;
} */
.Settings {
  z-index: 9999999;
}
.Header {
  z-index: 999;
}
.MobileMenu .HamBurgerMenu .Settings {
  display: flex;
}
@media (max-width: 1065px) {
  .Settings {
    display: none;
  }
  /* .MobileMenu .HamBurgerMenu .Settings {
    display: flex;
    height: 99.2vh;
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
  }
  .Settings ul {
    padding: 15rem 0;
  }
  .MenuSection {
    padding: 5px 0;
    flex-direction: row-reverse;
  }
  .Music,
  .Sound {
    flex-direction: row-reverse;
  }
  .MenuSection .NormalUrl {
    font-size: 3rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 3rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 4rem;
  }
  .Settings ul li {
    font-size: 3rem;
  } */
}
.SettingsOverlay {
  /* background-color: red; */
  z-index: 999;
}
.addressPopup {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b8fe7;
  border: 1rem solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 40rem;

  /* width: fit-content; */

  /* width: 15%; */
}
.Overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
}
.addressPopup .popupSection .popupImage {
  width: 40%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addressPopup .popupSection .popupImage img {
  width: 100%;
  object-fit: contain;
}
.addressPopup .popupSection .popupText {
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.addressPopup .popupSection .popupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  width: 300px;
  font-weight: 500;
  /* word-wrap: break-word; */
  line-height: 25px;
}
/* .popupButton button{
    margin: 1.5rem;
    width: fit-content;
    padding: 1rem 3rem;
    background-color: transparent;
    border:3px solid #fff;
    outline:none;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 30px;
} */
.addressPopup .popupSection .ReportPopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  width: 100%;
}
.addressPopup .popupSection .ReportPopupButton button {
  width: fit-content;
  margin: 1rem 2rem;
  padding: 1rem 3rem;
  background-color: transparent;
  border: 1.75px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  border-radius: 30px;
}
/* .popupInput textarea{
    width: 95%;
    padding:2rem 2rem;
    border-radius: 30px;
    color: #1b8fe7;

    outline:none;
    margin: 1rem;
} */
.addressPopup .popupSection .popupText form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.addressPopup .popupSection .popupText form input,
.popupSection .popupText form textarea {
  border: none;
  outline: none;
  border-radius: 15px;
  margin: 5px 0px;
  padding: 10px 20px;
  font-family: myraidproregular;
  color: #1b8fe7;
  font-size: 2rem;
}
.addressPopup .popupSection .popupText form textarea {
  /* border-radius: 25px; */
  font-family: myraidproregular;
  color: #1b8fe7;
  resize: none;
  height: 250px;
  font-size: 1.5rem;
}
.addressPopup .popupSection .popupText form input::-webkit-input-placeholder,
.popupSection .popupText form textarea::-webkit-input-placeholder {
  color: #1b8fe7;
  opacity: 1;
}
.addressPopup .popupSection .popupText form input,
.addressPopup .popupSection .popupText form textarea {
  margin: 6px 0px;
  padding: 10px 10px;
  font-size: 2rem;
}
@media (max-width: 1065px) {
  .addressPopup {
    padding: 1rem 2rem;
  }
}
@media (max-width: 599px) {
  .addressPopup {
    width: 34rem;
    height: 52rem;
    padding: 1.5rem;
  }
  .addressPopup .popupSection .popupText form input,
  .popupText form textarea {
    margin: 5px 0px;
    padding: 10px 10px;
    font-size: 1.5rem;
  }
  .addressPopup .popupSection .ReportPopupButton button {
    margin: 1.5rem 1rem;
    padding: 1rem 3rem;
    font-size: 1.5rem;
  }
  .addressPopup .popupSection .popupText form textarea {
    height: 180px;
    font-size: 1.5rem;
  }
  .MobileMenu .HamBurgerMenu .Settings {
    height: 100vh;
    width: 100%;
    border-radius: 0;
    top: 100%;
    transform: translateY(-100%);
    overflow: scroll;
    padding: 10rem 4rem;
  }
  .popup {
    width: 288px;
  }
  .MLogo {
    width: 10%;
  }
  .notificationBadge {
    width: 13px;
    height: 13px;
    font-size: 1rem;
    /* margin-left: 1.75rem; */
    right: 0.75rem;
    margin-top: 0;
  }
  .HomeMenuIcons svg {
    margin-right: 1rem;
  }
  .NotificationCount {
    width: 13px;
    height: 13px;
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  .Settings ul {
    padding: 20% 0;
  }
  .MenuSection .NormalUrl {
    font-size: 2.25rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 2.25rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 2rem;
  }
  .Settings ul li {
    font-size: 2.25rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 2.75rem;
    margin-top: 0;
  }
  .MenuSection .NormalUrl {
    font-size: 2.25rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 2.25rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 2rem;
  }
  .Settings ul li {
    font-size: 2.25rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 2.75rem;
    margin-top: 0;
  }
  .MenuSection {
    padding: 0px 0;
    flex-direction: row-reverse;
  }
  .Music,
  .Sound {
    flex-direction: row-reverse;
  }
}
@media (min-width: 380px) and (max-width: 420px) {
  .CartHistory {
    width: 95%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    height: 53rem;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .MobileMenu .HamBurgerMenu .Settings {
    height: 100vh;
    width: 100%;
    border-radius: 0;
    padding: 3rem 4rem;
    top: 100%;
    transform: translateY(-100%);
    overflow: scroll;
  }
  .Settings ul {
    padding: 25% 0;
  }
  .MenuSection {
    padding: 0px 0;
  }
}
@media (max-width: 280px) {
  /* .ContactSection p{
        font-size: 9rem;
        margin-bottom: 1rem;
    }
    .ContactSection .StrokeContact{
        font-size: 3.5rem;
    } */
  .addressPopup {
    width: 30rem;
  }
  .popup {
    width: 30rem;
  }
  .MenuSection .NormalUrl {
    font-size: 2rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 2rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 2rem;
  }
  .Settings ul li {
    font-size: 2rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 2rem;
  }
}
.addressPopup .popupSection {
  position: relative;
  z-index: 999;
  width: 100%;
}
.ShowCartHistory {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
}
.cartHistoryOverlay {
  position: absolute;
  z-index: 99;
  width: 100vw;
  height: 100vh;
}
.CartHistory {
  position: absolute;
  width: 60rem;
  top: 50%;
  left: 50%;
  height: 72rem;
  width: 60rem;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  background-color: red;
  border: 0.5rem solid #fff;
  z-index: 999;
  padding-bottom: 25px;
  overflow: scroll;
}
.CartProducts {
  width: 100%;

  padding: 15px;
  /* background-color: #fff; */
  display: grid;
  overflow: scroll;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.CartProduct {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  border: 0.75rem #fff solid;
  /* overflow: hidden; */
  height: 30rem;
}
.CartImage {
  width: 100%;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
}
.CartImage img {
  width: 100%;
  height: auto;
}
.CartContent {
  text-align: center;
}
.CartContent p {
  font-size: 2rem;
  font-weight: 600;
  padding: 15px;
}
.CartTitle {
  font-size: 4.25rem;
  color: #fff;
  font-family: drumshake_italic;
  text-align: center;
}
.histoyImageDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
}
.cartTitleImage {
  width: 35%;
}

@media (max-width: 1065px) {
  .CartHistory {
    width: 40%;
    transform: translate(-50%, -50%);
  }
  .CartProducts {
    max-height: 50vh;
    height: fit-content;
    /* width: 40%; */
    grid-template-columns: repeat(2, 1fr);
    /* height: 50vh; */
  }
}
@media (max-width: 1064px) {
  .CartHistory {
    width: 80%;
    height: 67vh;
    transform: translate(-50%, -50%);
    overflow: scroll;
  }
  .CartProducts {
    /* width: 40%; */
    /* height: 55vh; */
    /* padding-bottom: 25px; */
    grid-template-columns: repeat(2, 1fr);
    max-height: 95%;
  }
  .CartProduct {
    height: 28vh;
    width: 30rem;
  }
  .CartContent p {
    font-size: 2rem;
    padding: 25px;
  }
  .CartImage {
    height: 21rem;
  }
  .CartImage img {
    object-fit: contain;
  }
}
@media (max-width: 820px) {
  .MLogo {
    height: 100%;
    position: absolute;
    top: 0%;
    left: 7.5%;
    transform: translate(-25%, 70%);
    z-index: 0;
  }
}
@media (min-width: 700px) and (max-width: 768px) {
  /* .CartContent p {
    font-size: 2rem;
  } */
  /* .CartImage {
    height: 15rem;
  } */
  .MLogo {
    height: 100%;
    position: absolute;
    top: 0%;
    left: 7.5%;
    transform: translate(-25%, 70%);
    z-index: 0;
  }
  .BottomMenuIcon:nth-child(3) {
    width: 10.8%;
    margin-top: 1rem;
  }

  .MobileMenu .HamBurgerMenu .Settings {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
    top: 100%;
    transform: translateY(-100%);
    padding: 5rem;
  }
  .Settings ul {
    padding: 15rem 0;
  }
  .MenuSection {
    padding: 5px 0;
    flex-direction: row-reverse;
  }
  .Music,
  .Sound {
    flex-direction: row-reverse;
  }
  .MenuSection .NormalUrl {
    font-size: 3rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 3rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 4rem;
  }
  .Settings ul li {
    font-size: 3rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 5rem;
    margin-top: 0;
    margin-top: 0;
  }

  /* .Settings ul {
    padding: 4rem 0;
  } */
  /* .MenuSection {
    padding: 10px 0;
  } */
}
@media (max-width: 712px) {
  .MLogo {
    width: 7%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 7.5%;
    transform: translate(-25%, 70%);
  }
}

.historyEmptyText {
  font-size: 3rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  /* text-transform: capitalize; */
  /* margin-top: 20%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
@media (max-width: 765px) {
  .historyEmptyText {
    font-size: 1.75rem;
  }
  .CartHistory {
    width: 60%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    height: 51rem;
  }
  .CartProducts {
    grid-template-columns: repeat(2, 1fr);
    max-height: 95%;
  }
  .CartProduct {
    height: 20rem;
    width: 17rem;
  }
  .CartImage {
    height: 12rem;
  }
  .CartContent p {
    font-size: 1.25rem;
    padding: 15px;
  }
}

.kickoutPopupAll {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999999;
}
.KickoutOverlay {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #00000034;
}
.kickoutPopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999999;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 30px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
}

.KickoutBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 99;
}

.KickoutBtn button {
  margin: 1.5rem 1rem;
  padding: 1rem 0rem;
  width: 30%;
  background-color: transparent;
  border: 2px solid #fff;
  outline: none;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 100;
  border-radius: 30px;
  font-family: myraidproregular;
}
.popupText {
  margin-top: 15%;
  position: relative;
  z-index: 99;
  padding: 0rem 2rem;
}

.KickoutMessage {
  margin-top: 15%;
  padding: 0 2rem;
}
.KickoutMessage p {
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.5rem;
  font-weight: 100;
}
@media (max-width: 599px) {
  .popupText {
    padding: 0 1rem;
  }
  .popupText p {
    font-weight: 500;
  }
  .ReportPopupButton button {
    font-weight: 500;
  }
  .BottomMenuIcon:nth-child(3) {
    width: 11%;
    /* margin-top: 5px; */
  }
  .MLogo {
    width: 11%;
    transform: translate(-27%, 50%);
  }
  .CartHistory {
    width: 40rem;
    transform: translate(-50%, -50%);
    overflow: hidden;
    height: 51rem;
  }
}
@media (min-width: 380px) and (max-width: 420px) {
  .CartHistory {
    width: 40rem;
    transform: translate(-50%, -50%);
    overflow: hidden;
    height: 51rem;
  }
}
@media (max-width: 554px) {
  .MLogo {
    width: 10%;
    transform: translate(-27%, 40%);
  }
}
@media (max-width: 420px) {
  .MLogo {
    width: 11%;
    transform: translate(-27%, 50%);
    z-index: 0;
  }
}
@media (max-width: 280px) {
  .MPoints p {
    font-size: 1.35rem;
  }
  .BottomMenuIcon .CartBadge {
    height: 15px;
  }
}
/* @media screen and (min-device-width: 1024px) and (orientation: landscape) {
  .MLogo {
    width: 7%;
    top: 3px;
  }
  .CartHistory {
    width: 95%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    height: 55vh;
  }
  .CartProducts {
        height: 45vh;
    grid-template-columns: repeat(2, 1fr);
  }
  .CartProduct {
    height: 20vh;
  }
  .CartImage {
    height: 13rem;
  }
  .CartContent p {
    font-size: 1.5rem;
  }
} */
@media (max-width: 768px) {
  /* .addressPopup {
    width: 45rem;
  } */
  .addressPopup .popupSection .popupText {
    margin-top: 20%;
  }
}

@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .addressPopup {
    width: 45rem;
  }
  .addressPopup .popupSection .popupText {
    margin-top: 20%;
  }
  .CartHistory {
    width: 77%;
    transform: translate(-50%, -50%);
    overflow: scrol;
    height: 66vh;
  }
  .histoyImageDiv img {
    width: 30%;
  }
  .CartProducts {
    /* width: 40%; */
    padding: 12px;
    grid-template-columns: repeat(2, 1fr);
  }
  .CartProduct {
    height: 45rem;
    width: 42rem;
  }
  .CartImage {
    height: 31rem;
  }
  .CartContent p {
    font-size: 3rem;
    padding: 20px;
  }
  .MobileMenu .HamBurgerMenu .Settings {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
    top: 100%;
    transform: translateY(-100%);
    padding: 5rem;
  }
  .Settings ul {
    padding: 15rem 0;
  }
  .MenuSection {
    padding: 5px 0;
    flex-direction: row-reverse;
  }
  .Music,
  .Sound {
    flex-direction: row-reverse;
  }
  .MenuSection .NormalUrl {
    font-size: 3rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 3rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 4rem;
  }
  .Settings ul li {
    font-size: 3rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 5rem;
    margin-top: 0;
  }
  .BottomMenuIcon .CartBadge {
    width: 22px;
    height: 22px;
    font-size: 2rem;
    margin-left: 12rem;
    padding: auto;
    margin-bottom: 8rem;
  }
}
@media screen and (min-device-width: 1024px) {
  .MobileMenu .HamBurgerMenu .Settings {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
  }
}
@media screen and (max-device-width: 1400px) and (orientation: landscape) {
  .addressPopup {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1b8fe7;
    border: 1rem solid #949393;
    border-radius: 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: fit-content; */
    /* position: absolute; */
    width: 45rem;

    /* width: fit-content; */

    /* width: 15%; */
  }
  .CartHistory {
    width: 49%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    height: 74vh;
  }
  .CartProducts {
    /* width: 40%; */
    max-height: 95%;
    grid-template-columns: repeat(2, 1fr);
  }
  .CartProduct {
    height: 37rem;
    width: 35rem;
  }
  .histoyImageDiv img {
    width: 25%;
  }
  .CartImage {
    height: 25rem;
  }
  .CartImage img {
    object-fit: contain;
  }
  .CartContent p {
    font-size: 2.5rem;
    padding: 30px;
  }
}
@media screen and (max-device-width: 1200px) and (orientation: landscape) {
  .addressPopup {
    width: 43rem;
  }
  .addressPopup .popupSection .popupText {
    margin-top: 20%;
  }
  .CartHistory {
    width: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    height: 82vh;
  }
  .CartProducts {
    grid-template-columns: repeat(2, 1fr);
    max-height: 95%;
  }
  .CartProduct {
    height: 35vh;
    width: 30rem;
  }
  .CartImage {
    height: 21rem;
  }
  .CartImage img {
    object-fit: cover;
  }
  .CartContent p {
    font-size: 2.5rem;
    padding: 30px;
  }
  .cartTitleImage {
    width: 25%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .addressPopup {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1b8fe7;
    border: 1rem solid #949393;
    border-radius: 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: fit-content; */
    /* position: absolute; */
    width: 40rem;

    /* width: fit-content; */

    /* width: 15%; */
  }
  .CartHistory {
    width: 48%;
    transform: translate(-50%, -55%);
    overflow: hidden;
    height: 73vh;
  }
  .CartProducts {
    /* width: 40%; */
    grid-template-columns: repeat(2, 1fr);
  }
  .CartProduct {
    height: 30vh;
    width: 25rem;
  }
  .CartImage {
    height: 18rem;
  }
  .CartContent p {
    font-size: 2rem;
    padding: 20px;
  }
  .MobileMenu .HamBurgerMenu .Settings {
    display: flex;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    border-radius: 0;
    top: 100%;
    transform: translateY(-100%);
    padding: 3rem 4rem;
  }
  .MobileMenu .HamBurgerMenu svg {
    font-size: 3rem;
    margin-top: 8%;
  }
  .MenuSection .NormalUrl {
    font-size: 2rem;
  }
  .MenuSection .ActiveUrl {
    font-size: 2rem;
  }
  .MenuSection .NormalUrl:hover {
    font-size: 2rem;
  }
  .Settings ul li {
    font-size: 2rem;
  }
  .Settings ul {
    padding: 15rem 0;
  }
  .MenuSection {
    padding: 0px 0;
  }
  .HomeMenuIcons {
    height: 8rem;
  }
  .BottomMenuIcon {
    width: 5.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .BottomMenuIcon:nth-child(3) {
    width: 5.5%;
    margin-top: 1rem;
  }
  .BottomMenuIcon:nth-child(4) {
    width: 8%;
  }
  .HomeMenuIcons svg {
    font-size: 6rem;
  }
  .BottomMenuIcon .CartBadge {
    width: 30px;
    height: 30px;
    font-size: 2rem;
    margin-left: 10rem;
    padding: auto;
    margin-bottom: 5rem;
  }
}
@media screen and (min-device-width: 800px) and (max-device-width: 1000px) {
  .CartHistory {
    width: 72%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    height: 59vh;
  }
  .CartProducts {
    /* width: 40%; */

    grid-template-columns: repeat(2, 1fr);
    max-height: 95%;
  }
  .CartProduct {
    height: 25vh;
    width: 30rem;
  }
  .CartImage {
    height: 22rem;
  }
  .CartContent p {
    font-size: 2rem;
    padding: 20px;
  }
}

@media (max-width: 1065px) and (min-width: 700px) {
  .Slider {
    width: 15%;
  }
  .ActiveSlider {
    width: 15%;
  }
}
@media screen and (min-device-width: 800px) and (max-device-width: 1000px) and (orientation: landscape) {
  .CartHistory {
    height: 76vh;
    transform: translate(-50%, -55%);
    width: 33%;
  }
  .CartProduct {
    height: 30vh;
    width: 15rem;
  }
  .CartImage {
    height: 10rem;
  }
  .CartImage img {
    object-fit: contain;
  }
  .CartContent p {
    font-size: 1.5rem;
  }
  .addressPopup {
    align-items: center;
    background-color: #1b8fe7;
    border: 1rem solid #949393;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    padding: 0;
    position: fixed;
    top: 45%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 35rem;
    z-index: 99999;
    /* overflow: scroll; */
    height: 48rem;
  }
  .addressPopup .popupSection .popupImage {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 35%;
  }
  .addressPopup .popupSection .popupText {
    margin-top: 20%;
  }
  .addressPopup .popupSection .popupText form input,
  .addressPopup .popupSection .popupText form textarea {
    font-size: 1.5rem;
    margin: 4px 0;
    padding: 8px;
  }
  .addressPopup .popupSection .popupText form textarea {
    color: #1b8fe7;
    font-family: myraidproregular;
    font-size: 1.5rem;
    height: 150px;
    resize: none;
  }
  .addressPopup .popupSection .ReportPopupButton button {
    width: fit-content;
    margin: 1rem 2rem;
    padding: 1rem 3rem;
    background-color: transparent;
    border: 1.75px solid #fff;
    outline: none;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 30px;
  }
}
.bandaiLogo img,
.MBandaiLogo img {
  cursor: none;
}
.version {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
}
.Mobileversion {
  color: #fff;
  text-align: end;
  font-size: 1.5rem;
  margin: 0.75rem 0;
}
.warehousePopup {
  position: fixed;
  font-family: myraidproregular !important;
  z-index: 99999;
  top: 50%;
  left: 50%;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-50%, -50%);
  border: 1rem solid #949393;
  border-radius: 20px;
  background-color: #0a84fe;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 15%; */
  width: 40rem;
  height: 22rem;
}
.warehousePopupText {
  margin-top: 10%;
}
.warehousePopupText p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-size: 2.25rem;
  font-weight: 100;
}
.popupOverlaySection {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 9999;
}
.ResendpopupImage {
  width: 40%;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  /* width: 50%; */
}
.ResendpopupImage img {
  width: 100%;
}