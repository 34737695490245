.Container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Loader{
    width: 13rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150%);
}
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
/* }
@keyframes loader{
    to {
        transform: rotateZ(-360deg);
      }
} */
@media (max-width: 599px) {
    .Loader {
      width: 8rem;
    }
}